import React, { useRef, useState } from 'react';
import {
	TextField,
	InputAdornment,
	IconButton,
	Box,
	Typography,
	Button,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import Alert from '../../../components/alert';
import { AUTH_VIEW } from '../enum';
import Btn from '../../../components/btn/btn';
import { login, resendRegistrationOtp } from '../../../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { getProfile } from '../../../redux/profileSlice';
import { sendToken } from '../../../redux/generalReducer';
import { useToastAlert } from '../../../hooks';

const SignIn = ({ setCurrentAuthView, closeModal, callback, setPayload }) => {
	const [isLoading, setLoading] = useState(false);
	const alert = useToastAlert();
	const alertRef = useRef(null);
	const dispatch = useDispatch();
	const [isTogglePassword, setTogglePassword] = useState(false);

	const validationSchema = yup.object().shape({
		email: yup.string().email().required('Enter a email'),
		password: yup.string().required('Enter a password'),
	});

	const initialValues = {
		email: '',
		password: '',
	};

	const processSubmitDetails = async (payload) => {
		alertRef.current.close();
		try {
			setLoading(true);
			const res = await dispatch(login(payload)).unwrap();
			setLoading(false);

			// handle result here
			if (res.status === 'successful') {
				const isVerified = res.data?.verified;

				if (isVerified === false) {
					alert({
						message: 'Account not verify, verify you account to continue',
						type: 'info',
					});

					typeof setPayload === 'function' && setPayload(res.data);
					await dispatch(
						resendRegistrationOtp({ phone_number: res.data.phone_number })
					).unwrap();

					setCurrentAuthView(AUTH_VIEW.ENTER_CODE.value);

					return;
				}

				closeModal();

				const resData = await dispatch(getProfile()).unwrap();

				if (resData.status === 'success')
					typeof callback === 'function' && callback();
				const fbToken = JSON.parse(localStorage.getItem('fbToken'));
				if (fbToken) {
					await dispatch(sendToken({ token: fbToken })).unwrap();
				}
			}
		} catch (error) {
			alertRef.current?.show({ message: `${error.message}`, type: 'error' });
			setLoading(false);
		}
	};

	const { values, handleChange, handleSubmit, errors, touched } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			processSubmitDetails(values);
		},
	});

	const { email, password } = values;

	return (
		<Box
			sx={{
				display: 'grid',
				gap: '15px',
			}}
			component={'form'}
		>
			<Box sx={{ marginBottom: '20px' }}>
				<Typography variant='h4' sx={{ marinBottom: '8px' }}>
					Sign In
				</Typography>
				<Typography>Please fill in your details to continue</Typography>
			</Box>

			<Alert
				ref={alertRef}
				sx={{
					marginBottom: '20px',
				}}
			/>

			<TextField
				label={'Email'}
				type={'email'}
				error={Boolean(errors.email && touched.email)}
				helperText={touched.email && errors.email}
				variant={'outlined'}
				fullWidth
				placeholder='Enter email address'
				value={email}
				onChange={handleChange('email')}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '6px',
					alignItems: 'flex-end',
				}}
			>
				<TextField
					fullWidth
					type={isTogglePassword ? 'text' : 'password'}
					error={Boolean(errors.password && touched.password)}
					helperText={touched.password && errors.password}
					variant={'outlined'}
					label={'Password'}
					placeholder='Enter password'
					value={password}
					onChange={handleChange('password')}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									onClick={(e) => {
										setTogglePassword(!isTogglePassword);
									}}
								>
									{isTogglePassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>

				<Button
					disabled={isLoading}
					sx={{
						textTransform: 'capitalize',
						color: '#6438b0',
					}}
					onClick={(e) => {
						e.preventDefault();
						setCurrentAuthView(AUTH_VIEW.FORGOT_PASSWORD.value);
					}}
				>
					Forgot password?
				</Button>
			</Box>

			<Button
				disabled={isLoading}
				size='large'
				sx={{
					textTransform: 'capitalize',
					color: 'white',
					backgroundColor: '#6438b0 !important',
					display: 'flex',
					alignItems: 'center',
				}}
				onClick={(e) => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				{isLoading ? (
					<>
						<span>Processing...</span>
						{'  '}
						<CircularProgress size={16} />
					</>
				) : (
					'Continue'
				)}
			</Button>

			<Box
				sx={{
					textAlign: 'center',
					marginTop: '20px',
					span: {
						color: ' #6438b0',
						fontWeight: 'bold',
						cursor: 'pointer',
					},
				}}
			>
				Don’t have an account?{'  '}
				<span onClick={() => setCurrentAuthView(AUTH_VIEW.SIGNUP.value)}>
					Sign Up
				</span>
			</Box>
		</Box>
	);
};

export default SignIn;
