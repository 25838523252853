import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
	Box,
	Typography,
	TextField,
	Button,
	Container,
	styled,
	Divider,
	InputAdornment,
	IconButton,
	Checkbox,
	Autocomplete,
	FormHelperText,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { setKey, fromAddress } from 'react-geocode';
import { HexColorPicker } from 'react-colorful';
import { SketchPicker } from 'react-color';
import {
	Person,
	Badge,
	DirectionsCar,
	TwoWheeler,
	LocalShipping,
	Visibility,
	VisibilityOff,
} from '@mui/icons-material';
import DrawerAppBar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { grey, red } from '@mui/material/colors';
import PhoneTextField from '../../components/form-input/phone-text-field';
import ImageUpload from './components/image-upload';
import VehicleList from './components/vehicle-list';
import STATES from '../../utils/data/state';
import { BASE_URL } from '../../requestMethods';
import { useToastAlert } from '../../hooks';
import VerifyDriverModal from './components/verify-driver-modal';

setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

const VEHICLES = {
	Bike: 'bike',
	Car: 'car',
	Truck: 'truck',
};

const validationSchema = yup.object().shape({
	firstName: yup.string().required('Enter first name'),
	lastName: yup.string().required('Enter last name'),
	email: yup.string().email('Invalid email').required('Enter first name'),
	phoneNumber: yup.string().required('Enter phone number'),
	password: yup.string().required('Enter password'),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('password')], 'Password do not match')
		.required('Confirm password is required'),
	isAgreed: yup.boolean().oneOf([true], 'Accept terms and conditions'),
	bio: yup.string().required('Enter bio'),
	description: yup.string().required('Enter vehicle description'),
	vehicleType: yup.string().required('Select vehicle type'),
	plateNumber: yup.string().required('Enter vehicle plate number'),
	vehicleColorCode: yup.string().required('Select vehicle color'),
	city: yup.string().required('Select city'),
	state: yup.string().required('Enter state'),
	idCard: yup.string().required('Select ID card'),
	vehiclePhoto: yup.string().required('Select vehicle photo'),
	profilePhoto: yup.string().required('Select profile photo'),
	vehicleColor: yup.string().required('Specify vehicle color'),
});

const getLocation = () => {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				console.log(position);
			},
			(error) => {
				console.log(error);
			},
			{
				enableHighAccuracy: false,
				timeout: 30000,
				maximumAge: 0,
			}
		);
	} else {
		return 'Geolocation is not supported by this browser.';
	}
};

const AgreementList = ({ isChecked, text, onChange }) => {
	const handleChange = () => {
		if (typeof onChange === 'function') onChange(!isChecked);
	};
	return (
		<Box
			sx={{
				display: 'flex',
				gap: '6px',
				alignItems: 'center',
			}}
		>
			<Checkbox size='small' onChange={handleChange} checked={isChecked} />
			<Typography>{text}</Typography>
		</Box>
	);
};

const DriverRegister = () => {
	const initialValues = {
		firstName: '',
		lastName: '',
		phoneNumber: '',
		password: '',
		confirmPassword: '',
		bio: '',
		description: '',
		vehicleType: '',
		plateNumber: '',
		vehicleColorCode: '#fff',
		city: '',
		state: '',
		email: '',
		profilePhoto: '',
		idCard: '',
		vehiclePhoto: '',
		contractorAgreement: false,
		privacyPolicy: false,
		otpMessaging: false,
		vehicleColor: '',
	};

	const states = STATES;

	const alert = useToastAlert();
	const verifyModalRef = useRef();

	const [isTogglePassword, setTogglePassword] = useState(false);
	const [isToggleConfirmPassword, setToggleConfirmPassword] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const webToken =
		useSelector((store) => store.general.webToken) || 'no-device-id';

	const [profilePhoto, setProfilePhoto] = useState({
		croppedImageUrl: '',
		file: null,
	});

	const [idCard, setIdCard] = useState({
		croppedImageUrl: '',
		file: null,
	});

	const [vehiclePhoto, setVehiclePhoto] = useState({
		croppedImageUrl: '',
		file: null,
	});

	const onSubmit = async (values) => {
		if (
			!Boolean(
				values.contractorAgreement &&
					values.privacyPolicy &&
					values.otpMessaging
			)
		) {
			setFieldError('isAgreed', 'Agree to all conditions to continue');
			return;
		}

		const formData = new FormData();
		formData.append('name', `${values.firstName} ${values.lastName}`);
		formData.append('email', values.email);
		formData.append('phoneNumber', values.phoneNumber);
		formData.append('vehicleType', values.vehicleType);
		formData.append('password', values.password);
		formData.append('deviceId', webToken);
		formData.append('profilePhoto', profilePhoto.croppedImageUrl);
		formData.append('idCard', idCard.croppedImageUrl);
		formData.append('vehiclePhoto', vehiclePhoto.croppedImageUrl);
		formData.append('bio', values.bio);
		formData.append('description', values.description);
		formData.append('plateNumber', values.plateNumber);
		formData.append('city', values.city);
		formData.append('state', values.state);
		formData.append('vehicleColorCode', values.vehicleColorCode);
		formData.append('color', values.vehicleColor);
		formData.append('vehicleColor', values.vehicleColor);
		try {
			setLoading(true);
			const location = await fromAddress(`${values.city}, ${values.state}`);

			if (location && location.results) {
				const { lat, lng } = location.results[0].geometry.location;

				formData.append('latitude', lat);
				formData.append('longitude', lng);
			}

			const response = await axios.post(
				`${BASE_URL}/driver/register`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			if (response.data && response.data.status === 'successful') {
				verifyModalRef.current.open();

				alert({
					message: response.data.message,
					type: 'success',
				});
			}
		} catch (error) {
			if (error?.response) {
				alert({
					message: error.response.data.message || 'Something went wrong',
					type: 'error',
				});
			}
		} finally {
			setLoading(false);
		}
	};

	const {
		values,
		errors,
		touched,
		handleChange,
		handleSubmit,
		setFieldValue,
		resetForm,
		setFieldError,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	});

	const {
		firstName,
		lastName,
		phoneNumber,
		password,
		confirmPassword,
		bio,
		description,
		vehicleType,
		vehicleColorCode,
		plateNumber,
		city,
		state,
		email,
		contractorAgreement,
		privacyPolicy,
		otpMessaging,
		vehicleColor,
	} = values;

	const handleCallback = () => {
		resetForm();
		setProfilePhoto({
			croppedImageUrl: '',
			file: null,
		});
		setIdCard({
			croppedImageUrl: '',
			file: null,
		});
		setVehiclePhoto({
			croppedImageUrl: '',
			file: null,
		});
	};

	return (
		<Box>
			<VerifyDriverModal
				ref={verifyModalRef}
				email={email}
				phoneNumber={phoneNumber}
				callback={handleCallback}
			/>
			<DrawerAppBar />
			<Container>
				<Form component={'form'}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							gap: '6px',
							'h1, h3, p': {
								textAlign: 'center',
							},
							margin: '20px 0px',
						}}
					>
						<Typography variant={'h3'} component={'h1'}>
							Driver Registration
						</Typography>
						<Typography variant='h5' component={'h3'}>
							We need some basic information
						</Typography>
						<Typography variant='body2'>
							Kindly provide the details below
						</Typography>
						<Divider />
					</Box>
					<Box
						sx={{
							display: 'grid',
							gap: '15px',
						}}
					>
						<TextField
							fullWidth
							variant={'outlined'}
							label='First name'
							placeholder='First name'
							value={firstName}
							onChange={handleChange('firstName')}
							error={Boolean(touched.firstName && errors.firstName)}
							helperText={touched.firstName && errors.firstName}
						/>
						<TextField
							fullWidth
							variant={'outlined'}
							label='Last name'
							placeholder='Last name'
							value={lastName}
							onChange={handleChange('lastName')}
							error={Boolean(touched.lastName && errors.lastName)}
							helperText={touched.lastName && errors.lastName}
						/>
						<PhoneTextField
							fullWidth={true}
							onlyCountries={['us']}
							inputProps={{
								name: '',
								required: true,
								variant: 'standard',
							}}
							value={phoneNumber}
							onChange={handleChange('phoneNumber')}
							error={Boolean(touched.phoneNumber && errors.phoneNumber)}
							helperText={touched.phoneNumber && errors.phoneNumber}
						/>
						<Autocomplete
							fullWidth
							options={Object.keys(states)}
							error={Boolean(touched.state && errors.state)}
							helperText={touched.state && errors.state}
							value={state}
							onChange={(_, value) => setFieldValue('state', value)}
							getOptionLabel={(option) => option}
							renderOption={(props, option) => (
								<Box component='li' {...props}>
									{option}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select a state'
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
									error={Boolean(touched.state && errors.state)}
									helperText={touched.state && errors.state}
								/>
							)}
						/>
						<Autocomplete
							disabled={!state}
							fullWidth
							options={states[state] || []}
							error={Boolean(touched.city && errors.city)}
							helperText={touched.city && errors.city}
							value={city}
							onChange={(_, value) => setFieldValue('city', value)}
							getOptionLabel={(option) => option}
							renderOption={(props, option) => (
								<Box component='li' {...props}>
									{option}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select a city'
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
									error={Boolean(touched.city && errors.city)}
									helperText={touched.city && errors.city}
								/>
							)}
						/>
						<TextField
							fullWidth
							variant={'outlined'}
							label='Email'
							placeholder='Email address'
							value={email}
							onChange={handleChange('email')}
							error={Boolean(touched.email && errors.email)}
							helperText={touched.email && errors.email}
						/>
						<TextField
							fullWidth
							multiline
							rows={3}
							variant={'outlined'}
							label='Bio'
							placeholder='Bio'
							value={bio}
							onChange={handleChange('bio')}
							error={Boolean(touched.bio && errors.bio)}
							helperText={touched.bio && errors.bio}
						/>
						<ImageUpload
							text={'upload your profile'}
							aspect={1}
							setImageData={(photo) => {
								setFieldValue('profilePhoto', photo.croppedImageUrl);
								setProfilePhoto(photo);
							}}
							name={profilePhoto?.file?.name}
							icon={<Person />}
							error={Boolean(touched.profilePhoto && errors.profilePhoto)}
							helperText={touched.profilePhoto && errors.profilePhoto}
						/>
						<ImageUpload
							text={'Upload a valid ID'}
							aspect={16 / 9}
							setImageData={(photo) => {
								setFieldValue('idCard', photo.croppedImageUrl);
								setIdCard(photo);
							}}
							name={idCard?.file?.name}
							icon={<Badge />}
							error={Boolean(touched.idCard && errors.idCard)}
							helperText={touched.idCard && errors.idCard}
						/>
						<ImageUpload
							text={'Upload an image  of your vehicle'}
							aspect={1}
							setImageData={(photo) => {
								setFieldValue('vehiclePhoto', photo.croppedImageUrl);
								setVehiclePhoto(photo);
							}}
							name={vehiclePhoto?.file?.name}
							icon={<DirectionsCar />}
							error={Boolean(touched.vehiclePhoto && errors.vehiclePhoto)}
							helperText={touched.vehiclePhoto && errors.vehiclePhoto}
						/>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								gap: '6px',
								'h1, h3, p': {
									textAlign: 'center',
								},
								padding: '20px 0px',
							}}
						>
							<Divider
								sx={{
									marginBottom: '15px',
								}}
							/>
							<Typography variant='h5' component={'h3'}>
								Almost Done! <br />
								We need your vehicle information
							</Typography>
							<Typography variant='body2'>
								Kindly provide the details of your delivery vehicle
							</Typography>
						</Box>
						<Box>
							{touched.vehicleType && errors.vehicleType && (
								<FormHelperText sx={{ color: red['600'] }}>
									{errors.vehicleType}
								</FormHelperText>
							)}
							<Box>
								<VehicleList
									isChecked={vehicleType === VEHICLES.Bike}
									onChange={(value) => setFieldValue('vehicleType', value)}
									type={VEHICLES.Bike}
									icon={<TwoWheeler />}
								/>
								<VehicleList
									isChecked={vehicleType === VEHICLES.Car}
									onChange={(value) => setFieldValue('vehicleType', value)}
									type={VEHICLES.Car}
									icon={<DirectionsCar />}
								/>
								<VehicleList
									isChecked={vehicleType === VEHICLES.Truck}
									onChange={(value) => setFieldValue('vehicleType', value)}
									type={VEHICLES.Truck}
									icon={<LocalShipping />}
								/>
							</Box>
						</Box>
						<TextField
							fullWidth
							variant={'outlined'}
							label='Vehicle description'
							placeholder='Vehicle description'
							value={description}
							onChange={handleChange('description')}
							error={Boolean(touched.description && errors.description)}
							helperText={touched.description && errors.description}
						/>
						<TextField
							fullWidth
							variant={'outlined'}
							label='License number'
							placeholder='License number'
							value={plateNumber}
							onChange={handleChange('plateNumber')}
							error={Boolean(touched.plateNumber && errors.plateNumber)}
							helperText={touched.plateNumber && errors.plateNumber}
						/>
						{/* <TextField
							fullWidth
							variant={'outlined'}
							label='Vehicle color'
							placeholder='Vehicle color'
						/> */}
						<TextField
							fullWidth
							variant={'outlined'}
							label='Vehicle color'
							placeholder='Specify vehicle color'
							value={vehicleColor}
							onChange={handleChange('vehicleColor')}
							error={Boolean(touched.vehicleColor && errors.vehicleColor)}
							helperText={touched.vehicleColor && errors.vehicleColor}
						/>
						<Typography>Select vehicle color</Typography>
						<Box>
							<SketchPicker
								color={vehicleColorCode}
								onChange={(color) =>
									setFieldValue('vehicleColorCode', color.hex)
								}
							/>
							{touched.vehicleColorCode && errors.vehicleColorCode && (
								<FormHelperText sx={{ color: red['600'] }}>
									{errors.vehicleColorCode}
								</FormHelperText>
							)}
						</Box>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								gap: '6px',
								'h1, h3, p': {
									textAlign: 'center',
								},
								padding: '20px 0px',
							}}
						>
							<Divider sx={{ marginBottom: '15px' }} />
							<Typography variant='h5' component={'h3'}>
								Finally! <br />
								Setup login details
							</Typography>
							<Typography variant='body2'>
								Kindly provide your password information
							</Typography>
						</Box>
						<TextField
							fullWidth
							type={isTogglePassword ? 'text' : 'password'}
							variant={'outlined'}
							label={'Password'}
							placeholder='Password'
							value={password}
							onChange={handleChange('password')}
							error={Boolean(touched.password && errors.password)}
							helperText={touched.password && errors.password}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											onClick={(e) => {
												setTogglePassword(!isTogglePassword);
											}}
										>
											{isTogglePassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<TextField
							fullWidth
							type={isToggleConfirmPassword ? 'text' : 'password'}
							variant={'outlined'}
							label={'Password'}
							placeholder='Confirm password'
							value={confirmPassword}
							onChange={handleChange('confirmPassword')}
							error={Boolean(touched.confirmPassword && errors.confirmPassword)}
							helperText={touched.confirmPassword && errors.confirmPassword}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											onClick={(e) => {
												setToggleConfirmPassword(!isToggleConfirmPassword);
											}}
										>
											{isToggleConfirmPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<Box>
							<Typography>I agree to Bouk's</Typography>
							<AgreementList
								isChecked={contractorAgreement}
								onChange={() =>
									setFieldValue('contractorAgreement', !contractorAgreement)
								}
								text={'Independent Contractor Agreement'}
							/>
							<AgreementList
								isChecked={privacyPolicy}
								onChange={() => setFieldValue('privacyPolicy', !privacyPolicy)}
								text={'Privacy Policy & Terms of Service'}
							/>
							<AgreementList
								isChecked={otpMessaging}
								onChange={() => setFieldValue('otpMessaging', !otpMessaging)}
								text={
									'By checking this you opt-in to receive OTP messages via sms from Bouk.'
								}
							/>
							{touched.isAgreed && errors.isAgreed && (
								<FormHelperText sx={{ color: red['600'] }}>
									{errors.isAgreed}
								</FormHelperText>
							)}
						</Box>
					</Box>
					<Button
						disabled={isLoading}
						onClick={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
						size={'large'}
						sx={{
							backgroundColor: '#3e236e !important',
							color: 'white',
							textTransform: 'unset',
							minWidth: '120px',
							width: '100%',
							marginTop: '20px',
						}}
					>
						{isLoading ? 'Loading...' : 'Submit'}
					</Button>
				</Form>
			</Container>
			<Footer />
		</Box>
	);
};

const Form = styled(Box)(({ theme }) => ({
	maxWidth: '640px',
	width: '100%',
	margin: '10px auto 20px',
	border: `1px solid ${grey['300']}`,
	borderRadius: theme.spacing(1),
	padding: theme.spacing(2),
	backgroundColor: 'white',
}));

export default DriverRegister;
