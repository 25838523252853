import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ModalLayout from '../../../components/modal/modal-layout';
import { BASE_URL } from '../../../requestMethods';
import { useToastAlert } from '../../../hooks';

const VerifyDriverModal = (props, ref) => {
	const alert = useToastAlert();
	const [isDisplay, setDisplay] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isVerified, setVerified] = useState(false);

	const initialValues = {
		// phone_number: '',
		otp: '',
	};

	const email = props?.email;
	const phoneNumber = props?.phoneNumber;
	const callback = props?.callback;

	const validationSchema = yup.object().shape({
		otp: yup.string().required('OTP is required'),
	});

	const onSubmit = async (values) => {
		const payload = {
			otp: values.otp.toString(),
			phone_number: phoneNumber,
		};
		try {
			setLoading(true);
			const response = await axios.post(`${BASE_URL}/driver/verify`, payload, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});
			if (response && response.data.status === 'successful') {
				typeof callback === 'function' && callback();
				// closeModal();
				setVerified(true);
				// alert({
				// 	message: 'Account verify successful',
				// 	type: 'success',
				// });
			}
		} catch (error) {
			console.log(error);
			if (error?.response) {
				alert({
					message: error.response.data.message || 'Something went wrong',
					type: 'error',
				});
			}
		} finally {
			setLoading(false);
		}
	};

	const { values, handleChange, handleSubmit, errors, touched } = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	});

	const { otp } = values;

	const closeModal = () => setDisplay(false);

	useImperativeHandle(
		ref,
		() => ({
			open: () => setDisplay(true),
			close: closeModal,
		}),
		[]
	);

	if (isDisplay) {
		return (
			<ModalLayout
				isOpen={true}
				sx={{
					maxWidth: '540px',
				}}
				hasCloseButton={false}
				// closeModal={closeModal}
			>
				{isVerified ? (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '10px',
						}}
					>
						<Typography variant='h6'>Account Verification</Typography>
						<Typography
							sx={{ maxWidth: '420px', textAlign: 'center' }}
							variant='body1'
						>
							Your account is under review and we will update you on your
							verification status shortly. Kindly visit the playstore or app
							store to download the mobile app
						</Typography>
						<Button
							sx={{
								backgroundColor: '#3e236e !important',
								color: 'white',
								textTransform: 'unset',
								minWidth: '120px',
								marginTop: '20px',
							}}
							onClick={(e) => {
								closeModal();
								setVerified(false);
							}}
						>
							Close
						</Button>
					</Box>
				) : (
					<>
						<Box sx={{ marginBottom: '30px' }}>
							<Typography variant={'h5'}>Verify Account</Typography>
							<Typography variant='body1'>
								An OTP has been sent to you email {email}, please enter OTP to
								verify account
							</Typography>
						</Box>
						<Box
							sx={{
								display: 'grid',
								gap: '15px',
							}}
						>
							<TextField
								fullWidth
								type={'number'}
								variant={'outlined'}
								label='OTP'
								placeholder='Enter OTP'
								value={otp}
								onChange={handleChange('otp')}
								error={Boolean(touched.otp && errors.otp)}
								helperText={touched.otp && errors.otp}
							/>
							<Button
								sx={{
									backgroundColor: '#3e236e !important',
									color: 'white',
									textTransform: 'unset',
									width: '100%',
								}}
								onClick={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								{isLoading ? 'Loading...' : 'Verify'}
							</Button>
						</Box>
					</>
				)}
			</ModalLayout>
		);
	}
	return null;
};

export default forwardRef(VerifyDriverModal);
