import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AUTH_VIEW } from '../enum';
import { useDispatch } from 'react-redux';
import {
	resendRegistrationOtp,
	verifyRegistrationOtp,
} from '../../../redux/authSlice';
import { resetLoading } from '../../../redux/authSlice';
import { useToastAlert } from '../../../hooks';
import Alert from '../../../components/alert';

const VerifySignupAccount = ({ setCurrentAuthView, payload, setPayload }) => {
	const alert = useToastAlert();
	const dispatch = useDispatch();

	const email = payload?.email || '';
	const phone_number = payload?.phone_number || '';

	const alertRef = useRef(null);
	const counterRef = useRef(null);

	const [isLoading, setLoading] = useState(false);
	const [isResendingOtp, setResendingOtp] = useState(false);
	const [isDisabledResendBtn, setDisabledResendBtn] = useState(false);
	const [timer, setTimer] = useState(60);

	const initialValues = {
		code: '',
	};

	const validationSchema = yup.object().shape({
		code: yup.string().required('Enter verification code'),
	});

	const resetSetTimer = () => {
		clearInterval(counterRef.current);
		setDisabledResendBtn(false);
		setTimer(60);
		counterRef.current = null;
	};

	useEffect(() => {
		if (timer === 0) {
			resetSetTimer();
		}
	}, [timer]);

	const startTimer = useCallback(() => {
		setDisabledResendBtn(true);
		counterRef.current = setInterval(() => {
			setTimer((timer) => timer - 1);
		}, 1000);
	}, []);

	const onSubmit = async (values) => {
		alertRef.current.close();
		try {
			if (!phone_number && !email) {
				alertRef.current.show({
					message: `Something went wrong. Try again later.`,
					type: 'error',
				});
				return;
			}

			setLoading(true);

			const res = await dispatch(
				verifyRegistrationOtp({
					phone_number,
					otp: values.code,
				})
			).unwrap();
			resetLoading();
			setLoading(false);

			if (res.status === 'successful') {
				alert({
					message: res.message || 'Account verify successful! Log in.',
					type: 'success',
				});
				typeof setPayload === 'function' && setPayload(null);
				resetSetTimer();
				resetLoading();
				setCurrentAuthView(AUTH_VIEW.SIGNIN.value);

				return;
			}
		} catch (error) {
			setLoading(false);
			alertRef.current.show({ message: `${error.message}`, type: 'error' });
		}
	};

	const { values, handleChange, errors, touched, handleSubmit } = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	});

	const handleResendOtp = async (e) => {
		e.preventDefault();

		alertRef.current.close();

		try {
			if (!phone_number) {
				alertRef.current.show({
					message: `Something went wrong. Try again later.`,
					type: 'error',
				});

				return;
			}
			setResendingOtp(true);
			await dispatch(resendRegistrationOtp({ phone_number })).unwrap();

			setResendingOtp(false);

			alert({ message: 'Otp resend successfully!', type: 'success' });
			startTimer(); // Start timer...
		} catch (error) {
			setResendingOtp(false);

			alertRef.current.show({ message: `${error.message}`, type: 'error' });
		}
	};

	return (
		<Box
			sx={{
				display: 'grid',
				gap: '20px',
			}}
			component={'form'}
		>
			<Box>
				<Typography sx={{ marginBottom: '8px' }} variant='h4'>
					Verify Account
				</Typography>
				<Typography>
					Please enter the code that was sent to your email {email}.
				</Typography>
			</Box>
			<Alert
				ref={alertRef}
				sx={{
					marginBottom: '20px',
				}}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '6px',
					alignItems: 'flex-end',
				}}
			>
				<TextField
					fullWidth
					variant='outlined'
					placeholder={'Enter code '}
					value={values.code}
					onChange={handleChange('code')}
					helperText={touched.code && errors.code}
					error={Boolean(touched.code && errors.code)}
				/>
				<Box
					sx={{
						display: 'flex',
						gap: '8px',
						alignItems: 'center',
					}}
				>
					<Button
						disabled={isResendingOtp || isDisabledResendBtn}
						sx={{
							textTransform: 'capitalize',
							color: '#6438b0',
						}}
						onClick={handleResendOtp}
					>
						Resend Otp
					</Button>
					{timer !== 60 && <Typography>({timer}s)</Typography>}
				</Box>
			</Box>
			<Button
				disabled={isLoading}
				size={'large'}
				sx={{
					textTransform: 'capitalize',
					color: 'white',
					backgroundColor: '#6438b0 !important',
				}}
				onClick={(e) => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				{isLoading ? 'Verifying...' : 'Confirm'}
			</Button>
		</Box>
	);
};

export default VerifySignupAccount;
