import React, { useRef, useState } from 'react';
import { Box, Typography, styled, Divider, Avatar } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import DefaultButton from '../../../../../components/btn/default-button';
import TipDriver from '../../../../../components/tip-driver';
import PaymentCheckout from '../../../../../components/payment-checkout';
import { completeDelivery } from '../../../../../redux/orderSlice';
import { useToastAlert } from '../../../../../hooks';
import Loader from '../../../../../components/loader';
import DeliveryCompleted from '../../../../../components/delivery-completed';

const BoldText = styled(Typography)({
	fontWeight: 'bold',
});

const DeliveryDetails = ({ delivery }) => {
	const dispatch = useDispatch();
	const alert = useToastAlert();

	const [isCompleted, setCompleted] = useState(delivery?.completed || false);
	const [isCompletingDelivery, setCompletingDelivery] = useState(false);
	const [canTipDriver, setCanTipDriver] = useState(
		delivery?.tip?.Show || false
	);

	const driver = delivery?.driver;
	const deliveryDestination = delivery?.package_destination;
	const items = delivery?.items;
	const status = delivery?.status;
	const cashPaid = delivery?.cash_paid;

	const tipDriverRef = useRef(null);
	const paymentCheckoutRef = useRef(null);
	const deliveryCompletedRef = useRef(null);

	const handleClickRateDriver = () => {
		if (deliveryCompletedRef.current) deliveryCompletedRef.current.show();
	};

	const handleTipDriver = (token) => {
		paymentCheckoutRef.current.initialize(token);
	};

	const handleCallback = () => {
		setCanTipDriver(false);
		tipDriverRef.current.completeTip();
	};

	const handleMarkAsCompleted = async () => {
		try {
			setCompletingDelivery(true);
			const response = await dispatch(completeDelivery(delivery?.id)).unwrap();
			setCompletingDelivery(false);

			if (response.status === 'successful') {
				alert({
					message: `Delivery mark as completed successfully!`,
					type: 'success',
				});

				setCompleted(true);
				deliveryCompletedRef.current.show();
			}
		} catch (error) {
			setCompletingDelivery(false);
			alert({
				message: `${error.message}`,
				type: 'error',
			});
		}
	};

	return (
		<>
			{isCompletingDelivery && <Loader />}
			<DeliveryCompleted
				driver={driver}
				ref={deliveryCompletedRef}
				deliveryId={delivery?.id}
				tipDriver={() => {
					tipDriverRef.current.show();
				}}
			/>
			<PaymentCheckout ref={paymentCheckoutRef} callback={handleCallback} />
			<TipDriver
				ref={tipDriverRef}
				driver={driver}
				deliveryId={delivery?.id}
				tipDriver={handleTipDriver}
			/>
			<Box>
				<Box
					sx={{
						border: `1px solid ${grey['200']}`,
						borderRadius: '6px',
					}}
				>
					<Box
						sx={{
							backgroundColor: '#FFFCE5',
							padding: '20px',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								gap: '20px',
								alignItem: 'center',
								justifyContent: 'space-between',
								marginBottom: '10px',
							}}
						>
							<BoldText>Delivery #{delivery?.id?.substring(0, 8)}</BoldText>
							<BoldText>${cashPaid?.Value}</BoldText>
						</Box>
						<Box
							sx={{
								display: 'flex',
								gap: '20px',
								alignItem: 'center',
								justifyContent: 'space-between',
							}}
						>
							<Typography>
								{moment(delivery?.created_timestamp).format('l')}
							</Typography>
							<Typography>{status}</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							padding: '20px',
							display: 'grid',
							gap: '20px',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								gap: '20px',
								alignItems: 'center',
							}}
						>
							<Avatar src={driver?.PassportPhotographURL} />
							<BoldText>{driver?.Name}</BoldText>
						</Box>
						<Divider />
						<Box>
							<Typography
								variant='h5'
								sx={{ marginBottom: '10px', fontWeight: '300' }}
							>
								Delivery To
							</Typography>

							<BoldText>{deliveryDestination?.Name}</BoldText>
							<Typography>{deliveryDestination?.Address}</Typography>
						</Box>
						<Divider />
						<Box>
							<Typography
								variant='h5'
								sx={{ marginBottom: '10px', fontWeight: '300' }}
							>
								Delivery Items
							</Typography>
							<Box sx={{ display: 'grid' }}>
								{items &&
									items.length > 0 &&
									items.map((item, index) => {
										const hasDivider = items.length !== index + 1;
										return (
											<React.Fragment key={index}>
												<Box>
													<BoldText>{item?.PackageName}</BoldText>
													<Typography
														variant='body2'
														sx={{ color: grey['600'] }}
													>
														{item.Weight}lbs
													</Typography>
												</Box>
												{hasDivider && <Divider />}
											</React.Fragment>
										);
									})}
							</Box>
						</Box>
						<Box
							sx={{
								display: 'flex',
								gap: '10px',
							}}
						>
							{!isCompleted && (
								<DefaultButton
									onClick={handleMarkAsCompleted}
									sx={{
										color: 'white',
										flex: 1,
									}}
									buttonText={'Mark as Complete'}
								/>
							)}
							{isCompleted && canTipDriver && (
								<DefaultButton
									onClick={handleClickRateDriver}
									sx={{
										color: 'white',
										flex: 1,
									}}
									buttonText={'Rate Driver'}
								/>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default DeliveryDetails;
