import React from 'react';
import { Box, Typography, Divider, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ChevronRight } from '@mui/icons-material';
import moment from 'moment';

const BoldText = styled(Typography)({
	fontWeight: 'bold',
});

const DeliveryItem = ({ isActive, delivery, hasDivider, onClick }) => {
	const amount = delivery?.cash_paid?.Value;
	const createdAt = delivery?.created_timestamp;

	const subId = delivery?.id.substring(0, 8);

	return (
		<Box>
			<Box
				onClick={onClick}
				sx={{
					padding: '20px',
					cursor: 'pointer',
					position: 'relative',
					'&:after': {
						content: '""',
						display: isActive ? 'block' : 'none',
						position: 'absolute',
						height: '70%',
						width: '5px',
						backgroundColor: '#6438B0',
						right: 0,
						top: '50%',
						transform: 'translateY(-50%)',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						marginBottom: '10px',
					}}
				>
					<Typography
						sx={{
							fontWeight: 'bold',
							color: isActive ? '#6438B0' : undefined,
						}}
					>
						Delivery #{subId}
					</Typography>
					<Typography
						sx={{
							fontWeight: 'bold',
							color: isActive ? '#6438B0' : undefined,
						}}
					>
						${amount}
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Typography
						sx={{
							color: grey['500'],
						}}
						variant='body2'
					>
						{moment(createdAt).format('l')}
					</Typography>
					<ChevronRight />
				</Box>
			</Box>
			{hasDivider && <Divider sx={{}} />}
		</Box>
	);
};

export default DeliveryItem;
