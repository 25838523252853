import React from 'react';
import { Button } from '@mui/material';

const DefaultButton = ({
	isLoading,
	buttonText,
	loadingText = 'Loading...',
	children,
	fullWidth,
	size,
	sx,
	...rest
}) => {
	return (
		<Button
			size={size}
			disabled={isLoading}
			sx={{
				backgroundColor: '#3E236E !important',
				color: sx?.color,
				width: fullWidth ? '100%' : 'initial',
				textTransform: 'unset',
				...sx,
			}}
			{...rest}
		>
			{isLoading ? loadingText : children ? children : buttonText}
		</Button>
	);
};

export default DefaultButton;
