import React, {
	useImperativeHandle,
	forwardRef,
	useState,
	useRef,
} from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
	Box,
	Typography,
	Avatar,
	TextField,
	Rating,
	FormHelperText,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { rateDriver } from '../../redux/orderSlice';

import ModalLayout from '../modal/modal-layout';
import { grey, red } from '@mui/material/colors';
import DefaultButton from '../btn/default-button';
import Alert from '../alert';

const DeliveryCompleted = (props, ref) => {
	const dispatch = useDispatch();
	const alertRef = useRef(null);
	const [isDisplay, setDisplay] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [hasRateDriver, setRateDriver] = useState(false);

	const driver = props?.driver;
	const backToHome = props?.backToHome;
	const tipDriver = props?.tipDriver;
	const deliveryId = props?.deliveryId;

	const driverName = driver && driver['Name'];
	const driverId = driver && driver['ID'];

	useImperativeHandle(
		ref,
		() => ({
			show: () => {
				setDisplay(true);
			},
			close: () => setDisplay(false),
		}),
		[]
	);

	const closeModal = () => setDisplay(false);
	const handleTipDriver = () => {
		closeModal();
		typeof tipDriver === 'function' && tipDriver();
	};

	const handleBackToHome = () => {
		closeModal();
		typeof backToHome === 'function' && backToHome();
	};

	const initialValues = {
		rating: '',
		feedback: '',
	};

	const validationSchema = yup.object().shape({
		rating: yup.string().required('Specify rating'),
		feedback: yup.string(),
	});

	const onSubmit = async (values) => {
		try {
			setLoading(true);

			if (!deliveryId && !driverId) {
				alertRef.current.show({
					message: `Something went wrong try again later.`,
					type: 'error',
				});
				return;
			}

			const payload = {
				rating: parseFloat(values.rating),
			};

			if (values.feedback) payload.message = values.feedback;

			const response = await dispatch(
				rateDriver({
					id: driverId,
					data: payload,
				})
			).unwrap();
			setLoading(false);

			if (response.status === 'successful') {
				setRateDriver(true);
			}
		} catch (error) {
			setLoading(false);

			alertRef.current.show({
				message: `${error.message}`,
				type: 'error',
			});
		}
	};

	const { values, handleChange, handleSubmit, touched, errors, setFieldValue } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit,
		});

	if (isDisplay) {
		return (
			<ModalLayout
				isOpen={isDisplay}
				sx={{
					maxWidth: '540px',
				}}
				hasCloseButton={true}
				closeModal={closeModal}
			>
				<Alert
					ref={alertRef}
					sx={{
						marginBottom: '20px',
					}}
				/>
				<Box
					sx={{
						display: 'grid',
						gap: '15px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							img: {
								width: '100px',
								height: '100px',
							},
						}}
					>
						<img src='images/confetti.png' alt='confetti' />
					</Box>
					<Typography
						sx={{ textAlign: 'center', marginBottom: '20px' }}
						variant='h5'
					>
						Delivery Completed
					</Typography>
					<Box>
						<Box
							sx={{
								display: 'flex',
								gap: '15px',
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<Avatar
								sx={{
									height: '80px',
									width: '80px',
								}}
								src={driver['PhotoURL']}
								alt={'Driver Photo'}
							/>
							<Box>
								<Typography variant='h5'>{driverName}</Typography>
							</Box>
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '15px',
							alignItems: 'center',
						}}
						component={'form'}
					>
						{!hasRateDriver && (
							<>
								<Box>
									<Typography
										variant='body1'
										sx={{
											marginBottom: '6px',
											textAlign: 'center',
										}}
									>
										Rate Driver
									</Typography>
									<Rating
										name='simple-controlled'
										value={values.rating}
										onChange={(event, newValue) => {
											setFieldValue('rating', newValue);
										}}
									/>
									{touched.rating && errors.rating && (
										<FormHelperText
											sx={{
												color: red['700'],
												textAlign: 'center',
											}}
										>
											{errors.rating}
										</FormHelperText>
									)}
								</Box>
								<Box sx={{ width: '100%' }}>
									<Typography
										variant='body1'
										sx={{
											marginBottom: '6px',
										}}
									>
										Enter Feedback
									</Typography>
									<TextField
										multiline
										rows={4}
										fullWidth={true}
										placeholder='We take feedbacks seriously and use it to continually improve our service.'
										variant='outlined'
									/>
								</Box>
								<DefaultButton
									isLoading={isLoading}
									onClick={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
									fullWidth={true}
									size={'large'}
									buttonText={`Submit feedback`}
									sx={{
										color: 'white',
									}}
								/>
							</>
						)}
					</Box>
					<DefaultButton
						size={'large'}
						onClick={handleTipDriver}
						buttonText={`Tip ${driverName}`}
						sx={{
							backgroundColor: '#e0cffe !important',
							color: grey['800'],
						}}
					/>
				</Box>
			</ModalLayout>
		);
	}

	return null;
};

export default forwardRef(DeliveryCompleted);
