import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import FormIcons from '../../../components/form-icons/form-icons';
// import { ReactComponent as Edit } from '../../../assets/mode_edit.svg';
import { ReactComponent as ArrowRight } from '../../../assets/arrow-right.svg';
import PickupDriverLookup from '../../../components/pickup-driver-lookup';
import { Driver } from '../../../components/pickup-driver-lookup/driver-information';
import { useDispatch, useSelector } from 'react-redux';
import AppAlert from '../../../components/alert';
import {
	Typography,
	Box,
	Divider,
	Button,
	CircularProgress,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

import {
	getCostBreakdown,
	getDeliveryPaymentToken,
	resetOrderState,
} from '../../../redux/orderSlice';
import PaymentCheckout from '../../../components/payment-checkout';
import { useCancelDelivery, useToastAlert } from '../../../hooks';
import DefaultButton from '../../../components/btn/default-button';
import { grey } from '@mui/material/colors';
import { resetMapState } from '../../../redux/mapSlice';
import { resetTransactionState } from '../../../redux/transactionSlice';
import { onMessageListener } from '../../../messaging_init_in_sw';
import { DELIVERY_STATUS } from '../../../constant';
import DeliveryCompleted from '../../../components/delivery-completed';
import TipDriver from '../../../components/tip-driver';

const BoxContainer = styled(Box)({
	borderRadius: '5px',
	border: '1px solid rgba(0, 0, 0, 0.1)',
	padding: '15px',
	backgroundColor: 'white',
});

const BoldText = styled(Typography)({
	fontWeight: 'bold',

	span: {
		fontWeight: '400',
		color: '#7C7C7C',
		fontSize: '13px',
	},
});

const Flex = styled(Box)({
	display: 'flex',
	gap: '15px',
	width: '100%',
	justifyContent: 'space-between',
});

const DeliveryDetailsSummary = ({ increaseStep }) => {
	const { pickupvalue, delivervalue } = useSelector((state) => state.map);
	const navigate = useNavigate();
	const [driver, setDriver] = useState(null);
	const [isPaymentCompleted, setPaymentCompleted] = useState(false);
	const [deliveryStatus, setDeliveryStatus] = useState('');
	const [isTipDriver, setTipDriver] = useState(false);

	const alert = useToastAlert();

	const paymentCheckoutRef = useRef(null);
	const pickupRef = React.useRef(null);
	const deliveryCompletedRef = React.useRef(null);
	const alertRef = useRef(null);
	const tipDriverRef = useRef(null);

	const [isCancelingDelivery, setCancelingDelivery] = useState(false);

	const { cancelDelivery, isLoading } = useCancelDelivery((status, msg) => {
		setCancelingDelivery(false);
		if (status === 'failed') {
			alertRef.current.show({
				message: `${msg}`,
				type: 'error',
			});

			return;
		}
		navigate('/');
	});

	const [costBreakdown, setCostBreakdown] = useState(null);
	const [isGettingToken, setGettingToken] = useState(false);
	const [paymentResponse, setPaymentResponse] = useState('');
	const [isDeliveryCompleted, setDeliveryCompleted] = useState(false);

	const location = useLocation();
	const statePickupData = location.state?.pickupData;

	const { loading, error } = useSelector((state) => state.order);

	const dispatch = useDispatch();

	const handleGetPriceBreakdown = async (id) => {
		try {
			const response = await dispatch(getCostBreakdown(id)).unwrap();
			if (response.status === 'successful') {
				const data = response.data;
				setCostBreakdown(data);
			}
		} catch (error) {
			alertRef.current.show({
				message: `${error}`,
				type: 'error',
			});
		}
	};

	useEffect(
		() => {
			if (
				statePickupData &&
				statePickupData.deliveryData &&
				statePickupData.deliveryData.id
			) {
				handleGetPriceBreakdown(statePickupData.deliveryData.id);
			}
		},
		// eslint-disable-next-line
		[statePickupData]
	);

	const deliveryId =
		statePickupData &&
		statePickupData.deliveryData &&
		statePickupData?.deliveryData?.id;

	const handleProceedPayment = async (e) => {
		e.preventDefault();
		setGettingToken(true);

		try {
			const response = await dispatch(
				getDeliveryPaymentToken(deliveryId)
			).unwrap();
			if (response.status === 'successful') {
				const token = response.data.client_token;

				paymentCheckoutRef.current.initialize(token);
				// dispatch(setPaymentModal(false));
			}
		} catch (error) {
			alertRef.current.show({
				message: `${error.message}`,
				type: 'error',
			});
		}

		setGettingToken(false);
	};

	const handleNavigate = () => {
		navigate(`/delivery-steps`, {
			state: {
				pickupData: statePickupData,
				step: 4,
			},
		});
	};

	// Navigagte home
	const handleNavigateHome = () => {
		// Clear state
		dispatch(resetMapState());
		dispatch(resetTransactionState());
		dispatch(resetOrderState());
		setDriver(null);
		setTipDriver(false);
		setDeliveryCompleted(false);

		navigate('/');
	};

	const handleCallback = (status, message) => {
		if (status === 'success') {
			if (isTipDriver) {
				tipDriverRef.current.completeTip();

				return;
			}
			handleNavigate();
			pickupRef.current.init();
			setPaymentCompleted(true);
			setPaymentResponse(message);
			setTimeout(() => {
				setPaymentResponse('');
			}, 5000);
		}
	};

	// Message listener
	onMessageListener()
		.then((payload) => {
			const data = payload.data && JSON.parse(JSON.stringify(payload.data));

			// console.log('DATA::', data);

			if (data) {
				if (data.deliveryStatus === DELIVERY_STATUS.Accepted) {
					const body = JSON.parse(JSON.parse(JSON.stringify(data.body)));
					setDeliveryStatus(DELIVERY_STATUS.Accepted);
					setDriver(body);
				}

				if (data.deliveryStatus === DELIVERY_STATUS.Pickedup) {
					setDeliveryStatus(DELIVERY_STATUS.Pickedup);
				}

				if (data.deliveryStatus === DELIVERY_STATUS.DroppedOff) {
					setDeliveryStatus(DELIVERY_STATUS.DroppedOff);
					pickupRef.current?.close();
					deliveryCompletedRef.current.show();
				}

				if (data.deliveryStatus === DELIVERY_STATUS.Canceled) {
					setDeliveryStatus(DELIVERY_STATUS.Canceled);
				}
			}
		})
		.catch((err) => console.log('failed: ', err));

	// Cancel Delivery
	const handleCancelDelivery = () => {
		setCancelingDelivery(true);
		cancelDelivery(deliveryId);
	};

	// handle Tip Driver
	const handleTipDriver = (token) => {
		paymentCheckoutRef.current.initialize(token);
	};

	if (loading === 'loading' && !costBreakdown) {
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginBottom: '2rem auto',
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	if (loading === 'failed' && !costBreakdown) {
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					margin: '1rem auto',
				}}
			>
				<Typography>{error}</Typography>
			</Box>
		);
	}

	if (costBreakdown && Object.keys(costBreakdown).length > 0) {
		const { delivery_cost, service_charge, total_amount } = costBreakdown;
		return (
			<>
				<PickupDriverLookup
					ref={pickupRef}
					pickupCoordinate={statePickupData?.pickupCoordinate}
					deliveryId={deliveryId}
					message={paymentResponse}
					driver={driver}
					deliveryStatus={deliveryStatus}
				/>
				<DeliveryCompleted
					driver={driver}
					ref={deliveryCompletedRef}
					deliveryId={deliveryId}
					tipDriver={() => {
						setTipDriver(true);
						setDeliveryCompleted(true);
						tipDriverRef.current.show();
					}}
					backToHome={handleNavigateHome}
				/>
				<TipDriver
					ref={tipDriverRef}
					driver={driver}
					deliveryId={deliveryId}
					backToHome={handleNavigateHome}
					tipDriver={handleTipDriver}
				/>
				<Box>
					<PaymentCheckout ref={paymentCheckoutRef} callback={handleCallback} />
					<AppAlert sx={{ marginBottom: '25px' }} ref={alertRef} />
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
							gap: { xs: '1rem', md: '3rem' },
						}}
					>
						<Box>
							<Box
								sx={{
									display: 'grid',
									gap: '1rem',
								}}
							>
								<BoxContainer sx={{ display: 'flex', gap: '25px' }}>
									<FormIcons lineStyle={{ height: '40px' }} />
									<Box>
										<BoldText
											sx={{
												marginBottom: '20px',
											}}
										>
											Pickup up at? <br />
											<span style={{ fontWeight: '500' }}>
												{pickupvalue?.description}
											</span>
										</BoldText>
										<BoldText>
											Deliver at? <br />
											<span style={{ fontWeight: '500' }}>
												{delivervalue?.description}
											</span>
										</BoldText>
									</Box>
								</BoxContainer>
								<BoxContainer
									sx={{
										padding: '0px !important',
										paddingTop: '15px !important',
									}}
								>
									<Box
										sx={{
											marginBottom: '10px !important',
											padding: '0px 15px',
										}}
									>
										<BoldText
											sx={{
												fontSize: ['18px', '24px'],
											}}
											component={'h3'}
										>
											Your Delivery Item
										</BoldText>
									</Box>
									<Divider />
									<Box
										sx={{
											padding: '0px 15px',
										}}
									>
										{statePickupData &&
											statePickupData.items &&
											Array.isArray(statePickupData.items) &&
											statePickupData.items.map((item, index) => {
												const isLastItem =
													statePickupData.items.length === index + 1;
												return (
													<Box key={index}>
														<Box
															sx={{
																display: 'flex',
																gap: '15px',
																justifyContent: 'space-between',
																padding: '15px 0px',
															}}
														>
															<Box>
																<BoldText>
																	{item.name} <br />
																	<span>{item.description}</span>
																</BoldText>
															</Box>
															<BoldText>{item.weight}lbs</BoldText>
														</Box>
														{!isLastItem && <Divider />}
													</Box>
												);
											})}
									</Box>
								</BoxContainer>
							</Box>
						</Box>
						<Box>
							<Box
								sx={{
									display: 'grid',
									gap: '1rem',
								}}
							>
								<BoxContainer
									sx={{
										display: 'grid',
										gap: '15px',
									}}
								>
									<Flex>
										<BoldText>Price</BoldText>
										<BoldText>${delivery_cost}</BoldText>
									</Flex>
									<Flex>
										<BoldText>Service Charge</BoldText>
										<BoldText>${service_charge}</BoldText>
									</Flex>
									<Flex>
										<BoldText>Total</BoldText>
										<BoldText sx={{ fontSize: '18px' }}>
											${total_amount}
										</BoldText>
									</Flex>
								</BoxContainer>

								<BoxContainer>
									<BoldText>
										Note
										<br />
										<span>{statePickupData?.note}</span>
									</BoldText>
								</BoxContainer>
								{!isPaymentCompleted && (
									<>
										<Button
											disabled={isGettingToken}
											onClick={handleProceedPayment}
											sx={{
												backgroundColor: '#3E236E !important',
												color: 'white',
												display: 'flex',
												justifyContent: isGettingToken
													? 'center'
													: 'space-between',
												textTransform: 'capitalize',
												padding: '15px',
												height: '50px',
											}}
											fullWidth
										>
											{isGettingToken ? (
												'Processing...'
											) : (
												<>
													<span>Proceed to make payment</span>
													<ArrowRight />
												</>
											)}
										</Button>

										<Button
											onClick={handleCancelDelivery}
											disabled={isGettingToken || isCancelingDelivery}
											sx={{
												textTransform: 'capitalize',
												color: '#3E236E !important',
												justifySelf: 'center',
											}}
										>
											{isCancelingDelivery
												? 'Canceling request'
												: 'Cancel Request'}
										</Button>
									</>
								)}
								{driver && (
									<>
										<BoxContainer
											sx={{
												display: 'grid',
												gap: '20px',
											}}
										>
											<Driver driver={driver} />
											{!isDeliveryCompleted && (
												<>
													<Divider sx={{ margin: '8px 0px' }} />
													<Button
														onClick={handleCancelDelivery}
														disabled={isGettingToken || isCancelingDelivery}
														sx={{
															textTransform: 'capitalize',
															color: '#3E236E !important',
															justifySelf: 'center',
														}}
													>
														{isCancelingDelivery
															? 'Canceling request'
															: 'Cancel Request'}
													</Button>
												</>
											)}
										</BoxContainer>
										<Box>
											{!isDeliveryCompleted && (
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'space-between',
														gap: '10px',
														marginBottom: '20px',
													}}
												>
													<Typography>Track Request</Typography>
													<ChevronRight />
												</Box>
											)}
											<Divider sx={{ margin: '15px 0px' }} />
											<Box
												sx={{
													display: 'grid',
													gridTemplateColumns: 'repeat(2, 1fr)',
													gap: '10px',
												}}
											>
												<DefaultButton
													onClick={handleNavigateHome}
													sx={{
														backgroundColor: '#e0cffe !important',
														color: grey['800'],
													}}
													fullWidth={true}
													size={'large'}
													buttonText={'Back to Home'}
												/>

												<DefaultButton
													onClick={handleNavigateHome}
													fullWidth={true}
													sx={{
														color: 'white',
													}}
													size={'large'}
													buttonText={'Done'}
												/>
											</Box>
										</Box>
									</>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</>
		);
	}

	return null;
};

export default DeliveryDetailsSummary;
