import React from 'react';
import { Box, Typography, Radio } from '@mui/material';

const vehicleList = ({ type, isChecked, onChange, icon }) => {
	const handleChange = () => {
		if (typeof onChange === 'function') {
			onChange(type);
		}
	};
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '8px',
				}}
			>
				{icon}
				<Typography>{type}</Typography>
			</Box>
			<Radio onChange={handleChange} checked={isChecked} value={type} />
		</Box>
	);
};

export default vehicleList;
