import React from 'react';
import { Box, Typography, styled, Container } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AppContainer from '../../components/container/app-container';
import {
	POLICIES_SECTIONS,
	PRIVACY_POLICIES,
} from '../../constant/privacy-policies';

const ListItem = ({ text, count }) => {
	return (
		<Box sx={{ display: 'flex', gap: '10px', marginLeft: '10px' }}>
			{count ? (
				<Typography>{count}.</Typography>
			) : (
				<Circle sx={{ fontSize: '10px', marginTop: '5px' }} />
			)}
			{typeof text === 'string' ? <Typography>{text}</Typography> : text}
		</Box>
	);
};

const DriverPrivacyPolicies = () => {
	return (
		<AppContainer>
			<Container
				sx={{
					display: 'grid',
					gap: '30px',
					marginTop: ['2rem'],
					marginBottom: ['2rem'],
				}}
			>
				<BoldText variant={'h4'}>Privacy Policy for Drivers</BoldText>
				<Typography>
					<BoldText component='span' variant='body1'>
						Bouk LLC{' '}
					</BoldText>
					(Company Registration Number EIN 88-0635653) situated at 321 Westlawn
					Road, Columbia, South Carolina, 29210, United States of America, is
					the controller of personal data of Customers.
				</Typography>
				<Typography sx={{ span: { fontWeight: 'bold' } }}>
					The terms “Our”, “Us” or “We” contained herein, are words that are
					collectively referred to as {'  '} <span>“Bouk LLC”,</span>
					{'  '}a limited liability company incorporated & registered under the
					laws of The United States of America, which is highly devoted to
					protecting your privacy.
				</Typography>
				<Typography>
					This Privacy Policy explains how we collect, use, disclose and protect
					Information of our Bouk Drivers (“herein referred to as Boukers”) in
					their respective capacity as Boukers. It applies to all Personal
					information collected from such Boukers by us on the web pages for
					Boukers and the Bouker mobile application, and any other website or
					mobile application that links to this Policy, as well as during any
					written, electronic, and oral communications (herein referred to as
					the "Bouker Services").
				</Typography>
				<Typography sx={{ span: { textDecoration: 'underline' } }}>
					Your access to and use of the Bouk services are subject to our
					{'  '}
					<span>Independent Contractor Agreement</span>
					{'  '}
					(herein referred to as “ICA”) kindly ensure that you have
					painstakingly read and understood the ICA before you make use of the
					Bouker services. By making use of the Bouker services, you accept the
					ICA, and accept our privacy practices clearly outlined in this Policy.
					Whereas you are not confident or feel unsettled with any part of this
					Policy or our T&C, you are at liberty to refrain from using or
					accessing our services.
				</Typography>
				<Typography
					sx={{
						a: {
							fontWeight: 'bold',
							color: 'inherit',
							textDecoration: 'underline !important',
						},
					}}
				>
					Bouk provides an online marketplace platform, using web-based and
					mobile application (App) technology that connects, consumers, Boukers
					and other businesses utilizing the Bouk marketplace (“Merchants”). If
					you are using Bouk’s online marketplace in your capacity as a consumer
					accessing or ordering services, please visit the {'  '}
					<Link to='/privacy-policies'>User/Customer Privacy Policy</Link>{' '}
					{'  '}
					for information on how Bouk collects and uses consumer Personal
					Information.
				</Typography>
				<Typography>
					The Bouk services are subject to change at any time. As a result, we
					retain the exclusive and unrestricted right to change or modify this
					Policy at any time and without notice. We will tell you if we make any
					significant amendment to the way we handle your Personal Information
					(PI) before they take effect. If you don't agree with any of the
					updates, you must stop using the Bouk services. We urge that you
					examine this Policy on a regular basis. This Policy went into effect
					the day you signed up. Continued usage of Bouk services following any
					modifications or updates to this Policy signifies your acceptance of
					the amended Policy's provisions.
				</Typography>
				<Box>
					<BoldText hasUnderline>1. Your personal data we may collect</BoldText>
					<br />
					<ListItem
						text={`Name, phone number, e-mail address, postal or delivery address, account name,
						signature, or other similar identifiers.`}
					/>
					<br />
					<ListItem
						text={`To assess whether you qualify to be a Bouker and to determine if you are eligible to
							provide deliveries of alcohol, Bouk may be collect your age, year, or date of birth.
							We may collect face data such as photo, facial recognition, voice, or other biometric
							data.`}
					/>
					<br />
					<ListItem
						text={`Vehicle information including but not limited to; license plate, registration number
						and insurance information).`}
					/>
					<br />
					<ListItem
						text={`Vehicle information including but not limited to; license plate, registration number
and insurance information).`}
					/>
					<ListItem
						text={`Driver's license, profession, photo, and identity documents.`}
					/>
					<br />
					<ListItem
						text={`Bouk may collect financial information to (for example) facilitate payments to
Boukers. Bank and bank account details, including wiring and account numbers, as
well as order payment details, including expenditure refund requests, may be
included.`}
					/>
					<br />
					<ListItem
						text={`
					We capture analytical and web or other internet traffic data related with your
					interaction on our website, which may include information about your browser and
					operating system, IP address (your computer's Internet address), personal and online
					identifiers, unique device identifiers, and other information like your device type
					and version of the app you're using.`}
					/>
					<br />
					<ListItem
						text={`
					The financial statements of conducting transportation services are not deemed as
					personal data because drivers supply services during economic and professional
					activity. Hence, data about criminal convictions and offences are not requested.
					`}
					/>{' '}
					<br />
					<ListItem
						text={
							<Typography sx={{ fontWeight: 'bold' }}>
								Data related to communication through instant messages directly
								in the Bouk app{'  '}
								<span>("Send a Message" option):</span>
								{'  '} time and date of the communication and its content. When
								you use the Bouker services, fill out electronic forms,
								communicate with our customers, or notify us via live chat, e -
								mail, mobile, or SMS, we may auto record and save certain
								details about you and the interaction you involved in, such as
								your contact details; details that you willfully supply to us;
								the essence of your interaction; the intent of the engagement;
								and the intervention we took in reply to your inquiry or
								request, for example.
							</Typography>
						}
					/>
					<br />
					<ListItem
						text={`
					To make your use of the site more productive, we collect information about your
preferences through the use of cookies.
					`}
					/>
					<br />
					<ListItem
						text={`
					We may also collect your preferences, such as, preferred region and city, language
					preference, address, and preferences to receive texts instead of telephone calls.
					`}
					/>{' '}
					<br />
					<ListItem
						text={
							<Typography>
								We may also collect your preferences, such as, preferred region
								and city, language preference, address, and preferences to
								receive texts instead of telephone calls. <br /> <br />
								We may offer referral bonuses, as set forth in any referral
								terms governing the Bouker Referral Program. To help you do
								this, we may request that you grant us access to your mobile
								device address book. You can then direct us to send referral
								emails or other communications to contacts you select in your
								address book. By sending a referral, you also consent to Bouk
								sending your public profile image to the contacts you select to
								receive a referral link. You certify that you have gotten the
								friend's approval to share his or her contact information and
								name with us by opting to invite a friend. Bouk will use such
								data to send him or her an email encouraging them to visit the
								webpage and will store it in order to monitor the referral
								program's success rate and send marketing mailings. You can also
								send an SMS with a referral link to your contacts.
							</Typography>
						}
					/>
					<ListItem
						text={`If you are referred to Bouk through one of our referral programs, or if someone
attempts to refer you, we may share your name, whether you have already signed
up as a Bouker, whether you are a new Bouker, and whether you have completed
the required number of deliveries in the required amount of time to qualify for a
referral bonus.`}
					/>
				</Box>
				<Box>
					<BoldText hasUnderline>2. The essence of the processing</BoldText>{' '}
					<br />
					<ListItem
						text={`Bouk collects your personal data such as mobile number and email address to
enable Bouk to send you a one-time password (OTP) during sign up and password
reset.`}
					/>
					<br />
					<ListItem
						text={`We capture and use personal user information in order to match customers or
merchants with drivers to enable them to move around the city making
efficient deliveries or pickups.`}
					/>{' '}
					<br />
					<ListItem
						text={`
					Geo-location and driving routes are analyzed to provide options to drivers and to
					evaluate the geographic location. You must either shut the Bouk app or specify in
					
					the Bouk app that you are inactive and presently not offering pick-up or drop-
					off services if you do not want to reveal your location to customers.
					`}
					/>
					<br />
					<ListItem
						text={`To register as a Bouker, you must grant the Bouker Services authorization to enter
location services using your mobile phone operating system or browser's permission
system. When the Bouker app is running in the forefront or backdrop of your device,
or from a snapshot of a package that you give to us, we may acquire the specific
position of your device. When you start or participate in a delivery through the Bouk
Services (a "Bouk"), we use your geolocation data to authenticate that you are
actually in your desired region or city, link you with delivery options in your area,
and monitor the progress and completion of your request.`}
					/>
					<br />
					<ListItem
						text={`The compliance with regulatory criteria and acceptability of starting a career as a
driver are determined by analyzing the driver's license, profession, identity
documents, and criminal convictions and violations.`}
					/>
					<br />
					<ListItem
						text={`For the customer or merchant to recognize the driver and car, the Bouk app displays
the driver's photograph, name, and vehicle details.`}
					/>
					<br />
					<ListItem
						text={`Personal data related to instant messages directly in the Bouk app is processed in
order to provide service and customer support (including resolving disputes between
driver and customer/merchant), for security purposes as well as to improve our
products and services, and for analysis.`}
					/>{' '}
					<br />
					<ListItem
						text={`We may collect Personal Information about you from publicly available sources,
marketing partners, and/or third parties. This may include demographic information
such as income. We may also, for example, receive information about you from
Merchants regarding your deliveries through the Bouker Services.`}
					/>{' '}
					<br />
					<ListItem
						text={`
					When you use the Bouker Services as a Bouker, we may capture and retain
					data about your Bouks and deliveries, such as the timestamp of sign-up and log-in;
					delivery admissions and declines, as well as your acceptance rate and cancellation
					frequency. You make a certain number of Bouks and deliveries; Acceptances,
					declinations, cancellations, pick-ups, and completions of deliveries; the date and
					time of delivery acceptances, declinations, cancellations, pick-ups, and
					completions; The time it takes to finish a delivery or Bouk; Information on the bouk
					and delivery routes; Associated payment amounts for each delivery and Bouk;
					Number of deliveries per Bouk; Tip amounts; Customer evaluations and other
					comments; Attribution and conversion path of the user; Where did you have your
					instruction and where have you Bouked (day and hour of session); and Your current
					location (see Location Information section above).
					`}
					/>
					<ListItem
						text={`
					In connection with the Referral Program, Boukers may not email, publish, or
					broadcast any false, inaccurate, or manipulative communications. Such actions may
					violate applicable laws. Bouk users may not send any referral emails, SMS
					messages, or other communications to children under the age of 18.`}
					/>
				</Box>
				<Box>
					<BoldText hasUnderline>
						3. Legal grounds on which your Personal data may be collected
					</BoldText>{' '}
					<br />
					<ListItem
						text={`
					Personal information is analyzed in order to fulfill the contract with the driver. The
					processing of driver identification and geo-location data is a requirement for using
					the Bouk services.`}
					/>
					<br />
					<ListItem
						text={`
					Personal data may be processed in order to investigate and identify suspicious
					payments based on a legitimate interest. In addition, data about criminal records and
					offenses is handled to comply with a legal requirement.`}
					/>
				</Box>
				<Box>
					<BoldText hasUnderline>
						4. Recipients of your personal data we may collect
					</BoldText>
					<ListItem
						text={`
					The driver's photo, name, vehicle, phone number, and geo-location data will be
					visible to customers and merchants. Your personal information, on the other hand,
					is only shared with customers/merchants whose orders you have accepted.
					Customers and merchants can also view the personal information of the driver on
					the receipt.`}
					/>
					<br />
					<ListItem
						text={`
					Personal data will be processed in accordance with the terms set forth in this privacy
					notice. Furthermore, dependent on the driver's location, the driver's personal data
					may be given to Bouk local subsidiaries, representatives, partners, agents, and other
					third parties.`}
					/>
				</Box>
				<Box>
					<BoldText hasUnderline>
						5. Security and access of your personal data
					</BoldText>
					<br />
					<ListItem
						text={`
					Any personal data gathered during the provision of services is transmitted to and
					saved in the Amazon Web Services, Inc. data center, which is based in the United
					States of America and regions of European Union Member States. Only authorized
					team members of Bouk LLC and its affiliates have access to personal data, and they
					may only use it to resolve issues related to the usage of the services (including
					disputes regarding transportation services).`}
					/>{' '}
					<br />
					<ListItem
						text={`
					Personal data can be accessed by Bouk LLC and its partners to the extent necessary
					to provide customer service in the particular country.s`}
					/>
				</Box>
				<Box>
					<BoldText hasUnderline>
						6. Processing customers/users personal data
					</BoldText>{' '}
					<br />
					<ListItem
						text={`
					The norms and circumstances for processing personal data of users/customers must
					be followed as outlined in the Privacy Notice for Customers/Users. If you breach
					the regulations for the processing of personal data of customers, we may suspend
					your driver account and claim redress from you.`}
					/>{' '}
					<br />
					<ListItem
						text={`
					You may not process the personal data of customers without Bouk's approval. You

					may not engage any user or document, gather, retain, give access to, use, or cross-
					use the personal data provided by the customer or available to you via the Bouk app
					
					for any purposes other than to execute the delivery or pick-up services.`}
					/>{' '}
					<br />
					<ListItem
						text={`
					Customer service is used to settle disputes over the processing of personal data.`}
					/>
				</Box>
				<Box>
					<BoldText hasUnderline>
						7. Data correction, storage, erasure, and portability
					</BoldText>{' '}
					<br />
					<ListItem
						text={`
					Personal data can be viewed and corrected by sending a request notice to Bouk
					support.`}
					/>
					<br />
					<ListItem
						text={`
					As long as you have an active driver's account, your personal information will be
					kept on file.`}
					/>{' '}
					<br />
					<ListItem
						text={`Data required for accounting records will be kept for a period of eight years.`}
					/>{' '}
					<br />
					<ListItem
						text={`
					The data will be kept for 11 years if there is a suspicion of an administrative or
					criminal offense, forgery, or incorrect information.`}
					/>{' '}
					<br />
					<ListItem
						text={`
					In the situation of a conflict, the data must be kept until the claim is resolved or until
					the claim's expiration date.
					`}
					/>{' '}
					<br />
					<ListItem
						text={`
					It takes about a month for bouk representatives to respond to any e-mail request for
					erasing and exporting personal data and indicate the data erasure or transmission
					time.`}
					/>{' '}
					<br />
					<ListItem
						text={`
					The retention period for the data about instant messages directly in the Bouk app
					messages is 80 days, except cases where messages are related to a reported incident
					- in which case we will store them for 5 months.`}
					/>
				</Box>
				<Box>
					<BoldText hasUnderline>8. Account Deletion</BoldText>
					<br />
					<Typography>
						At Bouk, we prioritize the privacy and control of our drivers'
						personal information. As part of our commitment to transparency and
						user empowerment, we offer a comprehensive account deletion process.
						Drivers are provided with the option to request the deletion of
						their account, ensuring their ability to manage their data
						effectively. Below is an outline of our account deletion policy:
					</Typography>{' '}
					<br />
					<ListItem
						count={1}
						text={`Within the App: Drivers will find a clearly identifiable option within the app
interface to initiate the deletion of their account. This option is easily accessible and
ensures a seamless experience for drivers wishing to remove their account from the
app.`}
					/>
					<br />
					<ListItem
						count={2}
						text={`
					Outside the App: Drivers can also initiate the account deletion process by visiting
					our website. We ensure that the process for requesting account deletion is easily
					accessible and clearly outlined on our website, providing drivers with flexibility in
					managing their accounts.`}
					/>{' '}
					<br />
					<ListItem
						count={3}
						text={`
					Data Removal: Upon receiving a request for account deletion, we are committed to
					promptly removing all associated driver data from our systems. This includes any
					personal information or user-generated content that may be stored in our databases.`}
					/>{' '}
					<br />
					<ListItem
						count={4}
						text={`
					Data Retention for Legitimate Reasons: In certain circumstances, we may need to
					retain certain user data for legitimate reasons such as security, fraud prevention, or
					regulatory compliance. In such cases, we adhere to the following guidelines:`}
					/>{' '}
					<br />
					<ListItem
						text={`4.1: If there is suspicion of an administrative or criminal offense, forgery, or
incorrect information, the data will be kept for a period of 11 years.`}
					/>{' '}
					<br />
					<ListItem
						text={`4.2: Data required for accounting records will be retained for a period of eight years.`}
					/>
					<br />
					<ListItem
						text={`
					4.3: In situations of conflict, the data must be kept until the claim is resolved or until
					the expiration date of the claim.`}
					/>
					<br />
					<ListItem
						count={5}
						text={`
					All personal information, including account details for requests carried out and
					pending payments, will be deleted and non-retrievable for future payments. By
					deleting your account, you agree to the forfeiture of future or pending payments.`}
					/>
				</Box>
				<Box>
					<BoldText hasUnderline>9. Restricted Areas</BoldText> <br />
					<ListItem
						text={`
					Intending Boukers/Drivers who reside in California and the European Economic
					Area are presently not eligible to utilize Bouk platforms and services. You accept
					to only use Bouk Platforms and Services in the areas/regions/states/locations where
					Bouk operates. If you live in California or the European Economic Area ("EEA"),
					please, kindly do not download/use/access the Bouk Platform or Services, neither
					should you give nor enable Bouk receive personal information from you a California
					resident or EEA citizen.`}
					/>
				</Box>
			</Container>
		</AppContainer>
	);
};

const BoldText = styled(Typography)((props) => ({
	fontWeight: 'bold',
	textDecoration: props?.hasUnderline ? 'underline' : 'unset',
}));

export default DriverPrivacyPolicies;
