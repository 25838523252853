import React from 'react';
import { Box, Typography, Container, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import AppContainer from '../../components/container/app-container';

const TermsAndConditions = () => {
	return (
		<AppContainer>
			<Container
				sx={{
					display: 'grid',
					gap: '30px',
					marginTop: ['2rem'],
					marginBottom: ['2rem'],
				}}
			>
				<BoldText variant='h4' sx={{ textAlign: 'center' }}>
					Terms and Conditions of Service - United States
				</BoldText>
				<BoldText sx={{ textAlign: 'center' }}>
					BOUK Users <br />
					Terms and Conditions Agreement
				</BoldText>
				<Typography sx={{ textAlign: 'center' }}>
					ESSENTIAL: KINDLY PERUSE THE TERMS AND CONDITIONS THOROUGHLY. THE
					TERMS AND CONDITIONS (
					<BoldText component='span'>“AGREEMENT”</BoldText>) REPRESENT A LAWFUL
					AGREEMENT BETWEEN YOU AND BOUK LLC (COMPANY REGISTRATION NUMBER EIN
					88-0635653), LOCATED AT 321 WESTLAWN ROAD, COLUMBIA, 29210, SOUTH
					CAROLINA, UNITED STATES OF AMERICA (COLLECTIVELY,
					<BoldText component='span'>“BOUK,” “WE,” “US,” OR “OUR”</BoldText>
					).
				</Typography>
				<BoldText
					sx={{
						span: { color: 'red', textDecoration: 'underline' },
					}}
				>
					IT IS KEY THAT YOU PAY PARTICULAR ATTENTION TO {'  '}
					<span>PARAGRAPH 13</span>
					{'  '}
					OF THIS AGREEMENT THAT GOVERN DISPUTE RESOLUTIONS BETWEEN YOU AND
					BOUK.
				</BoldText>
				<Box>
					<BoldText>1. Capacity to Enter/Acceptance of this Agreement</BoldText>
					<Typography>
						As a company duly registered by law, Bouk, including any of its
						wholly owned subsidiary, provides an online market platform, which
						uses internet, web-based and mobile application technology to create
						an online forum where you can interact with other users, merchants,
						food places and/or other businesses and independent or private
						delivery contractors (“Contractors”). Bouk’s platform (the
						“Software”) grants users/customers the permission to request
						delivery pick-ups, drop-offs, and send request for different items,
						food and/or other goods from numerous places, namely, shops,
						residential addresses, restaurants and businesses, either for
						delivery pickup or drop-off. Soon as a request is made, the Software
						alerts Contractors that a delivery pick-up or drop-off opportunity
						is open, and the Software expedite execution of the request to the
						customer. The moment a pickup order is made, the Software
						communicates with the customer regarding the order for pickup.
						Kindly note that Bouk is neither a cafeteria, delivery company, or
						commercial kitchen company, nor is it a courier, shipping provider,
						common carrier, or food service provider. Meals, groceries, drinks,
						and other things brought to you are not provided by Bouk.
						Boukers/drivers are not our personnel, partners, or agents.
					</Typography>
					<br />
					<Typography>
						You, your descendants, identifies, and immediate predecessor
						(collectively, "you" or "your") recognize and justify that by
						accessing any of our websites located at{'  '}
						<Link to='/'>https://boukofficials.com</Link>
						{'  '}
						and installing or using our Bouk mobile application, or any other
						software distributed by Bouk, or accessing any relevant data,
						feature, or service offered or facilitated by Bouk (each, a
						"Service" and collectively, the "Services"), or completing the Bouk
						account registration process, thus depict and permit that:
					</Typography>
					<br />
					<Typography>
						(a) You have the power to engage in this Contract (individually,
						voluntarily, and intentionally) and, if appropriate, in
						representation of any business on whose authority you have opened a
						profile, and you have the authorization to tie such business to the
						Agreement.
					</Typography>
					<br />
					<Typography>
						(b) You have studied this Agreement thoroughly, completely
						comprehend it, and accept to be held by it.
					</Typography>
					<br />
					<Typography>
						(c) You have reached the lawful age of majority in your jurisdiction
						of residency and are able or allowed to enter into a legally
						enforceable agreement with Bouk.
					</Typography>
					<br />
					<Typography>
						All persons and other parties who enter or utilize the Services,
						including, without restriction, any businesses that sign up profiles
						or somehow utilize or use BOUK's Services through their different
						agents, are referred to as "User" or "Users." Inability to accept to
						be governed by the Agreement, except expressly stated in this
						Agreement, would result in entry or use of the Services being
						disallowed.
					</Typography>
				</Box>
				<Box>
					<BoldText>2. Modifications</BoldText>
					<br />
					<Typography>
						Bouk reserves the right to change the terms and conditions of this
						Agreement, as well as its policies pertaining to the Software and
						Services, at any moment, with immediate effect from the period an
						upgraded version of this Agreement is released through the Services,
						pursuant to Section 13(k) of this Agreement. As a result, you should
						check this Consent on a frequent basis, as your continuous use of
						the Services following any such modifications indicates your
						acceptance to the changes.
					</Typography>
				</Box>
				<Box>
					<BoldText>3. Regulations, Rules and Prohibitions</BoldText> <br />
					<Typography>
						Notwithstanding other regulations, rules and prohibitions contained
						herein, by registering an account and making use of the Services,
						you agree to the following:
					</Typography>{' '}
					<br />
					<Typography>
						(a) Only accurate information may be provided to us for registration
						of an account and prompt notification of any changes to such
						information as well as proof of identity shall be required and
						provided.
					</Typography>
					<br />
					<Typography>
						(b) The Solutions may be used solely in conformity with all relevant
						laws, including trademarks, patents, business secrets, and other
						third-party privileges, such as privacy and publicity rights.
					</Typography>{' '}
					<br />
					<Typography>
						(c) The Services may only be used in accordance with the means
						explicitly authorized by Bouk.
					</Typography>
					<br />
					<Typography>
						(d) The Software or any content, including Merchants’ menu content
						and reviews, displayed therethrough may not be copied or distributed
						for republication in any format or media.
					</Typography>
					<br />
					<Typography>
						(e) The Services should not be used to cause discomfort, trouble, or
						disturbance to others.
					</Typography>{' '}
					<br />
					<Typography>
						(f) The Services should not be used for impersonation of any
						individual or organization, or to falsify or distort labels or
						identifiers in order to conceal the source of any content delivered
						via the program.
					</Typography>
					<br />
					<Typography>
						(g) Unless Bouk's express approval has been requested and obtained,
						the Services, or the materials available therewith, should not be
						used for any marketing reasons, including but not restricted to
						engaging, promoting to, prospecting, or trading to any Merchant,
						User, or bouker/driver/Contractor.
					</Typography>{' '}
					<br />
					<Typography>
						(h) No content displayed through the Services may be compiled,
						directly or indirectly, with exception to personal, or
						non-commercial usage.
					</Typography>{' '}
					<br />
					<Typography>
						(i) The Services should only be used in accordance with the law.
					</Typography>{' '}
					<br />
					<Typography>
						(j) Other faults, defects, intrusion attempts tactics, or any
						infringement of our intellectual property rights that you discover
						while using the Services must be reported immediately.
					</Typography>
					<br />
					<Typography>
						(k) The user/customer should not use the Services in any manner that
						could harm, disrupt, overload, or hinder any Bouk server or the
						networks that link to it.
					</Typography>{' '}
					<br />
					<Typography>
						(l) The user/customer should not try to acquire illegal entry to any
						Bouk server's profile, asset, system software, or network.
					</Typography>{' '}
					<br />
					<Typography>
						(m) The user/customer must not explore, analyze, or assess the
						susceptibility of any network or system, or exploit or bypass any
						safety or authenticating measures implemented by Bouk to block or
						prohibit entrance to the Services or its content.
					</Typography>{' '}
					<br />
					<Typography>
						(n) The user/customer may not scrounge, duplicate, benchmark, shape,
						or track any part of our webpages or any material on our online
						sites by using any machine, crawlers, web sniffers, harvesting
						firmware, technical processes, or gadget to dark connect to our
						webpages or view our webpages mechanically or with any cyborg,
						crawlers, harvesting software, and/or machine.
					</Typography>{' '}
					<br />
					<Typography>
						(o) Scraping or other automated extraction of data or other
						information from the Services is not permitted.
					</Typography>{' '}
					<br />
					<Typography>
						(p) The user/customer must not take advantage of our special or
						voucher system by claiming numerous freebies at once or by creating
						different profiles to take advantage of limited-time deals.
					</Typography>{' '}
					<br />
					<Typography>
						(q) Any profile username and password or authentication details
						issued by us for accessibility to the Services should be held
						confidential and secure.
					</Typography>{' '}
					<br />
					<Typography>
						(r) While accessing the Services, the customer/user must not
						participate in or display abusive, insulting, racist,
						discriminatory, or any other conduct that Bouk views unacceptable.
					</Typography>{' '}
					<br />
					<Typography>
						Anyone found to be in breach of any of the foregoing regulations,
						rules and prohibitions shall have the account suspended and/or
						permanently deactivated without prior notification.
					</Typography>
				</Box>
				<Box>
					<BoldText>4. Additional Terms and Policies</BoldText> <br />
					<Typography>
						By accessing the Platform, the user/customer also recognizes and
						consents to Bouk's gathering, usage, and dissemination of personal
						data in compliance with Bouk's {'  '}
						<Link to='/privacy-policies'>Privacy Policy</Link> {' . '}, which is
						contained into this Agreement by reference. Furthermore, the
						customer accepts to be governed by any new rules, phrases, or
						regulations that may be posted on the webpage, software, or mobile
						phone app.
					</Typography>
				</Box>
				<Box>
					<BoldText>5. Contractors and Merchants Are Independent</BoldText>{' '}
					<br />
					<Typography>
						Bouk is a technological software platform linking the user/customer
						with independent service providers and others that sell the items
						offered through the Services ("Merchants"), as well as independent
						third-party contractors who perform delivery pick-up and drop-off
						services ("Contractors"). The user/customer acknowledges that Bouk
						bears no liability or legal accountability for the negligent acts of
						any Merchant or Contractor, and that BOUK just serves as a platform
						for the user/customer to interact with Merchants and Contractors.
					</Typography>
				</Box>
				<Box>
					<BoldText>6. User Account</BoldText> <br />
					<Typography>
						To access sections of the Services, a user/customer must sign up or
						own a profile, and during the enrollment process and at all other
						moments when the person accesses the Services, the customer must
						provide exact, existing, and accurate data, as well as frequently
						modify the details to maintain it authentic, recent, and
						comprehensive. The customer is the profile's only recognized and
						permitted user, and he or she or they must not reveal the profile's
						credentials to anyone else, and he or she or they must follow the
						terms of paragraphs 3 (a) to (s) of this Agreement. Please see
						paragraph 3 for more information.
					</Typography>
				</Box>
				<Box
					sx={{
						span: {
							fontWeight: 'bold',
						},
					}}
				>
					<BoldText>7. User Content</BoldText> <br />
					<Typography>
						(a) {'  '}
						<span>User Content. </span>
						{'  '}Bouk will offer participatory possibilities for the customer
						via its Services, including, for instance, the option to publish
						Customer reviews (collectively, "User Content"). The Customer
						affirms and declares that all Personal Information that they upload,
						publish, and/or alternatively communicate over the Platform is
						theirs or and they have the authority to publish it. Furthermore,
						the Subscriber signifies and authorizes that any Personal
						Information they disclose, publish, or convey via the Platform (i)
						does not possess any illegal information; (iii) doesn't breach any
						rule or policy; (ii) doesn't violate any third-party privilege, in
						addition to any patent rights, brand name, trademarks, proprietary
						information, confidentiality right, or other proprietary information
						or right; and (iv) does not hold content that is false, deliberately
						deceptive; (v) doesn't breach this Agreement. The Subscriber clearly
						and explicitly gives Bouk a continual, irreversible, exchangeable,
						totally paid, royalty-free, non-exhaustive, globally, complete
						sublicensable entitlement and permit to use, duplicate, showcase,
						publicize, customize, delete, publicly display, translate, transmit,
						and/or otherwise use the customer Information in relation with
						Bouk's business in all aspects now recognized or in the future
						created ("Uses"), without notice to and/or permission by the
						customer ("Uses"). The Customer also grants Bouk permission to
						utilize the Person's username, first name, and last initial, as well
						as other Customer account details, including User's rating history,
						without prior notice or authorization. <br /> <br />
						The Subscriber also acknowledges that the license issued to Bouk
						herewith includes authority for other Users to see and use their
						User Content in combination with involvement in the Services and as
						allowed by the Services' features and functions; and that the rights
						given to Bouk herewith shall outlive termination of the Services or
						the Person's profile. <br /> <br />
						Furthermore, the user acknowledges that, at Bouk's absolute
						discretion, it may analyze and/or remove the customer Content
						Posted. Bouk might also see, interpret, retain, and divulge
						information that it genuinely believes is essential to (a) implement
						this Agreement, such as assessment of breaches hereunder; (b) comply
						with any relevant legislation, legislation, judicial procedures, or
						governmental request; (c) identify, avert, or identify scams,
						privacy, or operational problems, (d) react to customer queries, or
						(e) safeguard the interest, assets, or stability of Bouk, its other
						Users, or third parties.
					</Typography>
					<br />
					<Typography>
						(b) {'  '}
						<span>Feedback.</span>
						{'  '} The User acknowledges that any views, recommendations, and/or
						propositions provided to Bouk via its feedback channels are
						contributed at the User's own discretion, and that Bouk offers the
						User no responsibilities in connection with such Feedback. The
						Subscriber signifies and authorizes that they hold every required
						permission to submit such Feedback, and herewith give to Bouk a
						continual, irreversible, exchangeable, royalty-free, globally, total
						sublicensable right and license to use, replicate, show, publicize,
						tweak, delete, display the work publicly, translate, disperse,
						and/or use such Feedback.
					</Typography>{' '}
					<br />
					<Typography>
						(c) {'  '}
						<span>Ratings and Reviews.</span>
						{'  '} Merchant or driver/bouker ratings and feedback ("Ratings" and
						"Reviews") are Users Data and are regulated by this Agreement. The
						Reviews and Comments do not reflect Bouk or its affiliates, and Bouk
						neither endorses nor accepts any culpability for the Customer
						testimonials or any allegations for financial damage deriving from
						the User ratings. To preserve the high degree of reliability with
						which we relate Reviews made accessible on the apps, the Customer
						accepts that: (I) they will desist from giving Evaluation for any
						Vendor or bouker/driver to which they possess ownership stake,
						employment contract, or other association, or for any of that
						organization's closest rivals; (ii) you will not submit a Review for
						monetary benefits, or other rewards from a Merchant or business;
						(iv) you will provide a Rating according to applicable laws Bouk
						retains the right delete any profile Content that breaches these
						provisions with respect to Ratings and Reviews and no prior notice
						shall be required in the circumstance.
					</Typography>
				</Box>
				<Box>
					<BoldText>8. Communications with Bouk</BoldText> <br />
					<Typography>
						The User agrees/opt in to obtain digital information from Bouk,
						Contractors, or third-party service providers, including emails,
						SMS, one-time password (OTP) or voice calls, to the mobile contact
						number or email address submitted to Bouk. These may include
						messages created by automated phone dialing systems and/or which
						will transmit programmed messages delivered by or on behalf of Bouk,
						its related companies, and/or Contractor, such as interactions
						regarding request made via your account on the Platform. It's
						possible that applicable charges will apply. Please keep in mind
						that delivery SMS sent between the customer and Boukers/drivers are
						totally transactional, and not advertisement. To make any
						modifications to the aforementioned, the customer can go to their
						profile's Settings and update their notification choices. You must
						reply "STOP" from the mobile number receiving the texts to opt out
						of getting marketing SMS from Bouk.
					</Typography>
				</Box>
				<Box>
					<BoldText>9. E-SIGN Disclosure</BoldText> <br />
					<Typography>
						In addition to the above, the User who creates and accesses the
						account in Bouk’s platform equally consents/opt in to the use of an
						electronic record to document your agreement, which consent may be
						withdrawn by emailing Bouk with “Revoke Electronic Consent” in the
						subject line. The Customer will need (i) a device (such as a
						desktop, laptop or smartphone) with a web browser and Internet
						connectivity, and (ii) either a printer or memory capacity on such
						gadget to read and maintain a version of this disclosure. Email Bouk
						with your contact details and postal address for a complimentary
						hardcopy or to modify our databases of your contact details.
					</Typography>
				</Box>
				<Box>
					<BoldText>10. Intellectual Property Ownership</BoldText> <br />
					<Typography>
						Bouk will own all rights, claim, and interests in and to the
						Software and Services, including any associated intellectual
						property rights (and its licensors, where relevant). This Agreement
						is not a purchase, and it does not grant the Customer or party
						rights of ownership in or relating to the Software or the Services,
						or any intellectual property rights held by Bouk. The Bouk
						identities, slogans, and marketing materials associated with the
						Software and Services are trademarks of Bouk or third parties, and
						no permission or license to use them is provided. Any copyright,
						trademark or other intellectual rights indications contained in or
						underlying the Software must not be removed, altered, or obscured by
						the Customer.
					</Typography>
				</Box>
				<Box
					sx={{
						span: { fontWeight: 'bold' },
					}}
				>
					<BoldText>11. Payment Terms</BoldText> <br />
					<Typography>
						(a) {'  '}
						<span>Prices & Charges.</span> {'  '} The Customer is completely
						aware of the following: (a) Bouk is under no responsibility to
						itemize its prices, earnings, or profitability when publicizing such
						costs; (b) the prices for menu or other items displayed through the
						Platform are not influenced by Bouk and (c) Bouk reserves the right
						to refuse service to anyone. The subtotals indicated at checkout for
						some transactions are approximations that may be greater or lesser
						based on the final in-store totals. Bouk retains the right to bill
						the total cost to the Customer's payment option after completion in
						those circumstances. Except for taxes based on Bouk's earnings, the
						Customer is responsible for any transactional taxes on the Services
						delivered under this Agreement. When the cost to a customer's
						payment option differs inaccurately from the full sum, including
						subtotal, surcharges, and tip, presented to you at checkout and/or
						after tip is chosen, Bouk retains the right an extra fee to the
						customer's payment option after the original charge to ensure that
						the overall sum charged matches the overall sum displayed. All
						transactions will be managed by Bouk or its payment provider and
						will be made using the customer's specified payment option. The card
						issuer may present us with revised card information if the
						customer's bank details changes. We may use these new details, as
						well as information from existing cards on record, to help avoid
						service interruptions. Kindly clarify your payment details if the
						customer wishes to use a different payment means or if the payment
						method changes.
					</Typography>{' '}
					<br />
					<Typography>
						(b) {'  '}
						<span>No Refunds.</span> {'  '} All fees for orders that have been
						fulfilled and received are irrevocable and non- refundable. Bouk is
						under no obligation to offer reimbursements or vouchers unless its
						assessment allows it.
					</Typography>{' '}
					<br />
					<Typography>
						(c) {'  '}
						<span>Promotional Offers and Credits.</span> {'  '} Bouk may offer
						special deals to any Customer, with varied features and fees, at its
						absolute discretion. These special offers are subject to the
						limitations of this Agreement and might only be applicable to a
						limited number of Customers, as specified in the promotion.
						Promotional offers: (i) are susceptible to the precise terms that
						Bouk sets for such special deal; (ii) should only be used by the
						target demographic, for the original intention, and in a legal way;
						(ii) should not be recreated, auctioned, transmitted, or made
						available to other people in any way, except if explicitly
						authorized by Bouk; (iv) cannot be exchanged for money or cash
						equivalent; and (v) are invalid after the date indicated. In the
						occasion where Bouk ultimately decides that the claiming of the
						promo or issuance was in error, untruthful, legally questionable, or
						in infringement of the relevant promotion contexts or this
						Agreement, Bouk retains the authority to withdraw or subtract reward
						points or benefits gained through a promotion. At all moments, Bouk
						maintains the authority to change or withdraw a deal. Bouk's special
						Offerings and Credits Terms and Conditions (which are contained this
						Agreement by default) is applicable to all special offers, and Bouk
						may amend its Promotional Offers Conditions at any moment. Bouk may
						also provide free credits that can be used to purchase Services.
						Except for where restricted by law, any credit provided by Bouk is
						redeemable for 6 months from the time issued and will not be
						converted to money or currency equivalents. Credits are withdrawn
						from the customer's profile after they expire. Credits that have
						lapsed cannot be applied to any transaction. Credits given via a
						Customer's Bouk profile can only be used to purchase services from
						that business.
					</Typography>{' '}
					<br />
					<Typography>
						(d) {'  '}
						<span>Referral Program.</span> {'  '} Bouk's Referral Program allows
						Users account in excellent shape to earn free Bouk credits by asking
						their qualified peers to sign up as novel Bouk Customers using a
						special referral code. The Customer might get a complimentary credit
						as mentioned on Bouk's Referral Program. The Customer agrees that
						Bouk has the right to amend or discontinue the Referral Program's
						rules at any moment.
					</Typography>{' '}
					<br />
					<Typography>
						(e) {'  '}
						<span>Fees for Services.</span> {'  '} Bouk reserves the right to
						collect charges for our Services as we see fit, including Delivery
						Fees, Service Fees, and Rush Fees. Bouk might also collect merchant
						fees, such as commissions and other payments, on request placed via
						the Platform, and fees may vary as we believe reasonable or suitable
						for our business or in accordance with applicable legislation.
					</Typography>{' '}
					<br />
					<Typography>
						(e) {'  '}
						<span>Gift Cards.</span> {'  '} Except as provided below, Gift Cards
						may be redeemable towards eligible orders placed on {'  '}
						<Link to='/'>www.boukofficials.com</Link> {'  '}
						or Bouk apps in the United States. However, if a user has a Gift
						Card that was purchased, they must use any remaining balance of that
						Gift Card on the service (Bouk) where the card was first redeemed.
						Gift Cards are made available and provided by Bouk LLC. Please note
						that Gift Cards are not redeemable for cash except when required by
						applicable law.
					</Typography>{' '}
				</Box>
				<Box
					sx={{
						span: { fontWeight: 'bold' },
					}}
				>
					<BoldText>12. Third-Party Interactions.</BoldText> <br />
					<Typography>
						(a) {'  '}
						<span>
							Third-Party Websites, Applications and Advertisements.
						</span>{' '}
						The Services may contain links to third-party websites (
						<span>“Third-Party Websites”</span>) and applications (
						<span>“Third-Party Applications”</span>) and advertisements (
						<span>“Third-Party Advertisements”</span>) (collectively,
						“Third-Party Websites & Advertisements”). When a customer clicks the
						link to a Third-Party Domain, Third-Party Application, or
						Third-Party Advertisement, Bouk does not alert the customer that
						they have left Bouk's webpage or Services and are now held to the
						terms and conditions of the Third-Party. Bouk has no jurisdiction
						over Webpages. Bouk does not evaluate, endorse, supervise, promote,
						certify, or support any Third-Party Webpages, or their offerings.
						All links in Third-Party Internet page are used at the subscriber's
						own peril. As a result, prior to engaging in any business with a
						third party, a subscriber could perhaps evaluate the relevant terms
						& conditions, including the confidentiality and security procedures
						of any Third-Party Webpages, and conduct whatever inquiry they deem
						necessary or helpful.
					</Typography>{' '}
					<br />
					<Typography>
						(b) {'  '}
						<span>App Stores.</span> {'  '}The Software and Platform are subject
						to the availability of the third party from which you obtained the
						application license, such as the Apple iPhone or Android app stores
						("Google play Store"). The customer and Bouk are the parties to this
						agreement, not the App Store. Bouk, and not the App Store, is
						entirely liable for the Software, along with the smartphone
						application(s), their contents, management, technical support, and
						warranties, as well as any disputes arising from them (such as legal
						compliance, or intellectual property infringement). The subscriber
						also accepts to pay any fees (if applicable) billed by the App Store
						in correlation with the Software; abide by pertinent third-party
						terms and conditions (e.g., the App Store's policies) when using the
						Software or the Services, and the customer's authorization to use
						the Software and the Services is inculcated on complying with all
						relevant third-party terms and conditions (e.g., the App Store's
						policies). The customer declares that they are not resident in a
						nation that is under United States sanction or declared a "terrorist
						sponsoring" terrain.
					</Typography>
				</Box>
				<Box
					sx={{
						span: { fontWeight: 'bold' },
					}}
				>
					<BoldText>13. Arbitration Agreement (Dispute Resolution).</BoldText>{' '}
					<br />
					<BoldText>
						KINDLY TAKE TIME TO READ THE FOLLOWING SECTION CAREFULLY. THE
						REQUIREMENT OF ARBITARTION IS ESPECIALLY ESSENTIALIT AS IT INVOLVES
						THE MEANS THROUGH WHICH DISPUTES WITH BOUK AND THE USER MAY BE
						RESOLVED.
					</BoldText>{' '}
					<br />
					<Typography>
						(a) {'  '}
						<span>Extent of Arbitration Agreement.</span> {'  '}The Customer
						accepts that any conflict pertaining in any way to their association
						or payments with Bouk as a Customer of our Services, to any business
						promotion involving Bouk or the Services, to any goods or services
						produced or dispersed using the Services that you obtained as a
						Customer of our Services, or to any facet of their interaction or
						payments with Bouk as a Customer of our Services will be settled by
						binding arbitration, instead of in court, excepting in situations
						where (1) the User or Bouk can actively sought adequate remedy in
						court for violation or misappropriation of intellectual property
						laws if the assertions meet the criteria for small claims court
						(such as trademarks, domain names, copyrights, and patents) and; (2)
						the customer might lay assertions in small claims court where such
						allegations qualify, as long as the case stays in such court and
						proceeds only on a personal level (non-class, non-representative)
						basis. This Arbitration Agreement shall be applicable to all matters
						that originated or were brought prior to the Effective Date of this
						Agreement, beyond restriction. Through the acceptance of this
						Arbitration Agreement, the User elects not to participate in any
						class action against Bouk but may claim against Bouk via arbitration
						in its individual capacity.
					</Typography>{' '}
					<br />
					<Typography>
						(b) {'  '}
						<span>Informal/Traditional Resolution.</span> {'  '}The Parties
						accepts that good-faith informal attempts to settle disagreements
						can most times lead to quick, moderate, and mutually advantageous
						results, and thus understand that, before any party seeks
						arbitration against the other, private discussions to seek
						unofficial approaches of resolution in good faith via video call or
						phone call must take place first. The originating party must provide
						the other a written statement of the anticipated informal discussion
						directly, and the conversation must take place 45 days after the
						other party gets such communication, unless a collectively
						satisfactory postponement is granted. The message might be sent to
						the official email addresses or to any other acceptable record-
						keeping method. Until the unofficial procedure is finished, the
						statute of limitations and other applicable statutes will be tolled.
					</Typography>{' '}
					<br />
					<Typography>
						(c) {'  '}
						<span>Arbitration Rules and Forum.</span> {'  '}This Arbitration
						Agreement covers disputes between the user and any BOUK affiliates,
						subsidiaries, successors, agents, and employees that arise out of or
						relate to this Agreement, but it does not apply to any claims that
						cannot be arbitrated under applicable law. To the extent the parties
						have both arbitrable and non-arbitrable disputes that are related,
						the arbitrable disputes shall proceed first in arbitration and the
						non-arbitrable disputes shall be stayed, and any applicable statutes
						of limitations tolled, pending completion of the arbitration. Even
						if the parties are protected from the FAA, the parties totally
						accept that the FAA will regulate this Agreement. Any disagreements
						over the FAA's interpretation will be decided by an independent
						arbitrator. The state law regulating arbitration agreements in the
						state where the customer operates will prevail if the FAA does not
						apply for whatever cause.
					</Typography>{' '}
					<br />
					<Typography>
						(d) {'  '}
						<span>Arbitrator Powers.</span> {'  '}Any conflict regarding the
						interpretation, validity, recognition and enforcement, or
						establishment of this Arbitration Agreement shall be resolved solely
						by the arbitrator, not by court at different levels or institution,
						including, but not restricted to, any assertion that some or all
						sections of this Arbitration Agreement is unenforceable. All
						disagreements over the fees of arbitrator, including the scheduling
						of such fees and sanctions for lack of payment, must be resolved by
						an arbitrator rather than a law court. The arbitration shall
						determine user and Bouk's rights and responsibilities, if any. The
						arbitration action cannot be merged with other cases or added with
						any other hearings or parties unless explicitly allowed in paragraph
						13(g) of this Agreement. The arbitrator will be able to accept
						petitions that will resolve all or some part of any allegation or
						disagreement. The arbitrator has the power to grant financial
						compensation and non-money related solution or remedies applicable
						to a person within relevant laws, the procedures of the arbitration
						venue, and this Agreement on a case-by-case basis (including this
						Arbitration Agreement). The arbitrator shall produce a formal report
						of ruling outlining the key observations and results that shall be
						used to make any judgment (or discretion not to make an award), as
						well as the compensation granted. The verdict is exclusively
						enforceable between the parties and has no bearing on any subsequent
						arbitration or other procedure involving a separate party. The
						arbitrator must adhere to all relevant laws. The arbitrator has
						similar ability as a judge in a law court to
					</Typography>{' '}
					<br />
					<Typography>
						(e) {'  '}
						<span>Waiver of Jury Trial.</span> {'  '}Except as provided in
						paragraph 13(a) earlier in the section, both Parties forego all
						constitutional and legal rights to sue in court and have a judge or
						jury trial, but rather choose to have disputes and litigation
						settled by arbitration. Arbitration does not have a judge or jury,
						and court scrutiny of an arbitration ruling is restricted.
					</Typography>
					<br />
					<Typography>
						(f) {'  '}
						<span>Waiver of Class or Joint Actions.</span> {'  '}The customer
						and bouk accept to forgo any ability to settle disputes inside the
						range of this arbitration agreement on a collective, group, or
						delegated grounds, pursuant to paragraph 13(g) of this agreement.
						Unless as provided in paragraph 13, all complaints, and
						disagreements inside the terms of this arbitration agreement shall
						be arbitrated on a case-by-case grounds, not on a class or group
						basis.
					</Typography>
					<br />
					<Typography>
						(g) {'  '}
						<span>Batch Arbitrations.</span> {'  '}To accommodate effectiveness
						and speed of settlement, the arbitrator shall group claims into
						batches and deliver negotiated settlements of each batch as an only
						one arbitration if 100 or more related arbitration claims against
						Bouk are presented to an arbitrator chosen in line with the
						regulations outlined above inside a 30-day period. You accept to
						work with Bouk and the arbitrator in good conscience to achieve a
						batch approach to settlement.
					</Typography>
					<br />
					<Typography>
						(h) {'  '}
						<span>Opt Out.</span> {'  '}A registered customer can send a
						declaration to BOUK stating that he or she or they intend to opt out
						of this Arbitration Agreement. If that is done, they should send
						BOUK a Notice via First Class Mail. The person's opt out statement
						should be mailed inside 30 days of the Agreement's start date to be
						valid. Both parties are presumed to have consented to this Mutual
						Arbitration Clause if the customer does not opt out within 30 days
						of the Agreement's start date.
					</Typography>
					<br />
					<Typography>
						(i) {'  '}
						<span>Modification.</span> {'  '}Any subsequent substantial update
						to this Arbitration Agreement made by Bouk, without detriment to any
						other element of the Agreement, will not be applicable to any
						personal issue(s) for which notification has been submitted to Bouk.
					</Typography>
					<br />
					<Typography>
						(j) {'  '}
						<span>Survival.</span> {'  '}If user engagement with Bouk ends, this
						Arbitration Agreement will continue to apply.
					</Typography>
					<br />
					<Typography>
						(k) This Arbitration Provision does not apply to conflicts amongst
						parties that are not entitled to a pre-dispute arbitration
						agreement, such as those granted by an Act of Congress or a
						legitimate, binding Executive Order.
					</Typography>
					<br />
					<Typography>
						(l) The CPR Rules can be accessed at {'  '}
						<Link to={'www.cpradr.org'}>www.cpradr.org</Link>
					</Typography>
				</Box>
				<Box>
					<BoldText>14. Social Media Guidelines.</BoldText> <br />
					<Typography>
						Bouk, being an online platform/marketplace, operates a number of
						social media accounts for the convenience of its users. You accept
						to follow our regulations by submitting, discussing, or engaging
						with these accounts.
					</Typography>
				</Box>
				<Box>
					<BoldText>Transactions Involving Alcohol</BoldText> <br />
					<Typography>
						Any liquor beverage request and delivery in the United States will
						be authorized only if the customer is 21 years of age or older. Any
						request for and delivery of liquor beverages in another country will
						only be valid if the customer is of lawful drinking age in the
						specific location. Additionally, when getting liquor, the customer
						is expected to submit acceptable government- issued identity to the
						Contractor providing the products in order to establish the
						receiver's age and verify that the receiver would not be drunk when
						obtaining such items. If the customer is under the age of 21,
						doesn’t present an acceptable government-issued Identity, the
						details on the ID does not fit the details on the request, or is
						clearly drunk, the Contractor can refuse the request or/and
						delivery. If the Contractor seems unable to finalize the delivery of
						liquor for any of the reasons mentioned above, the customer will be
						charged a non-refundable $50 restocking fee.
					</Typography>
				</Box>
				<Box>
					<BoldText>16. Indemnification</BoldText> <br />
					<Typography>
						A. The user accepts to indemnify, safeguard, shield and hold
						harmless BOUK, along with all parent, division, and/or related
						entities, as well as its historical and contemporary successors,
						executives, officials, stakeholders, intermediaries, legal teams,
						and workers, from any and all claims, requests, harm, cases,
						liabilities, deeds, and obligations originating wholly or in part
						from, as a consequence of, or in correlation with the user or/and
						their employees actions and/or omission resulting from the execution
						of the contracted service within this Agreement, also comprising of
						bodily harm or demise of any individual (including to the user
						and/or their workers) in addition to any obligation generating from
						the user's fault to obey and follow the terms of this Agreement. The
						user's obligations under this agreement include legal fees,
						including lawyers' fees, as well as the settlement of any final
						court ruling or resolution reached against BOUK or its parent,
						subsidiary, and/or related firms.
					</Typography>
					<br />
					<Typography>
						B. The terms in this paragraph will not be affected by the
						deactivation of the customer's profile, this agreement or the
						deletion or customer's accessibility of the Software or Services.
					</Typography>
				</Box>
				<Box>
					<BoldText>17. Disclaimer of Warranties</BoldText> <br />
					<Typography>
						The user clearly affirms that the use of the software is totally at
						their own risk, to the maximum level permitted legally.
						Modifications to the software can be made on a regular basis and can
						occur at any moment without prior notification. The software is
						supplied "as it is" with no expressly guarantees of any sort,
						including durability, compatibility for a specific reason, and non-
						infringement. Bouk offers no guarantees or claims about the
						information made accessible via the
						<br /> <br />
						software or services. Bouk does not guarantee that the software or
						services can run without errors or that they will be free of bug or
						other malware. If the customer requires to service or replace
						devices or data while using the software or services, bouk is not
						liable for the cost of doing so.
					</Typography>
				</Box>
				<Box>
					<BoldText>18. Internet Delays</BoldText> <br />
					<Typography>
						Constraints, interruptions, and other issues involved in the use of
						the Internet and digital interactions may apply to the Software and
						Services. Bouk is not liable for any disruptions, service failures,
						or damages, theft, or harm originating from such incidents, unless
						otherwise stated in Bouk's Privacy Policy or as mandated by relevant
						regulations.
					</Typography>
				</Box>
				<Box>
					<BoldText>19. Exclusive Venue</BoldText> <br />
					<Typography>
						Both the customer and Bouk accept that all issues and conflicts
						generating out of or connected to the Agreement will be resolved
						solely in the United States District Court for the District in which
						the customer resides, to the extent that both parties are authorized
						to do so within this Agreement.
					</Typography>
				</Box>
				<Box>
					<BoldText>20. Termination</BoldText> <br />
					<Typography>
						1. BOUK can rescind this Agreement and delete the CONTRACTOR’S
						Bouker profile only on the grounds stated in the BOUK POLICY to
						which the CONTRACTOR is in express agreement, or for a fundamental
						breach of this Agreement or any egregious actions or behavior. BOUK
						may also temporarily remove access to the platform of BOUK for the
						purposes of timely investigation where fraud or abuse is suspected,
						including circumvention of compliance with Legal Process, or when
						deemed necessary to protect the safety and security of the users of
						BOUK platform.
					</Typography>{' '}
					<br />
					<Typography>
						2. Bouk reserves the right to change or cancel the Software or
						Service, as well as amend, stop, or revoke your accessibility to the
						Software or Services, at any time or without warning to the customer
						also without liabilities to the user or any third party.
					</Typography>
					<br />
					<Typography>
						3. Bouk maintains the authority to enforce legal action, including
						civil, criminal, or injunction action, in relation to restricting or
						discontinuing the user's accessibility to the Software or the
						Service.
					</Typography>
					<br />
					<Typography>
						4. Even when the user's permission to access the Software or
						Services is revoked, this Agreement continues to be binding on them.
						All clauses that, by their essence, should outlive the revocation of
						this Agreement in order to give effectiveness to those clauses will
						do so.
					</Typography>
				</Box>
				<Box>
					<BoldText>21. Account Deletion</BoldText> <br />
					<Typography>
						At Bouk, we prioritize the privacy and control of our users'
						personal information. As part of our commitment to transparency and
						user empowerment, we offer a comprehensive account deletion process.
						Users are provided with the option to request the deletion of their
						account, ensuring their ability to manage their data effectively.
						Below is an outline of our account deletion policy:
					</Typography>{' '}
					<br />
					<Typography>
						1. Within the App: Users will find a clearly identifiable option
						within the app interface to initiate the deletion of their account.
						This option is easily accessible and ensures a seamless experience
						for users wishing to remove their account from the app.
					</Typography>
					<br />
					<Typography>
						2. Outside the App: Users can also initiate the account deletion
						process by visiting our website. We ensure that the process for
						requesting account deletion is easily accessible and clearly
						outlined on our website, providing users with flexibility in
						managing their accounts.
					</Typography>
					<br />
					<Typography>
						3. Data Removal: Upon receiving a request for account deletion, we
						are committed to promptly removing all associated user data from our
						systems. This includes any personal information or user-generated
						content that may be stored in our databases.
					</Typography>
					<Typography>
						4. Data Retention for Legitimate Reasons: In certain circumstances,
						we may need to retain certain user data for legitimate reasons such
						as security, fraud prevention, or regulatory compliance. In such
						cases, we adhere to the following guidelines:
					</Typography>
					<Typography>
						4.1: If there is suspicion of an administrative or criminal offense,
						forgery, or incorrect information, the data will be kept for a
						period of 11 years.
						<br />
						• 4.2: Data required for accounting records will be retained for a
						period of eight years. <br />• 4.3: In situations of conflict, the
						data must be kept until the claim is resolved or until the
						expiration date of the claim.
					</Typography>
					<br />
					<Typography>
						5. All personal information, including account details for requests
						carried out, previous requests, pending requests, and pending
						payments, will be deleted and non-retrievable for future purposes.
						By deleting your account, you agree to the forfeiture of future or
						pending payments and requests. Consequently, a Customer/User who has
						had their account deleted will not be able to utilize the Bouk app.
					</Typography>
				</Box>
				<Box>
					<BoldText>22. Restricted Area</BoldText> <br />
					<Typography>
						Any intending CONTRACTOR who resides in California and the European
						Economic Area is presently not eligible to utilize Bouk platforms
						and services. You accept to only use Bouk Platforms and Services in
						the areas/regions/states/locations where Bouk operates. If you live
						in California or the European Economic Area ("EEA"), please, kindly
						do not download/use/access the Bouk Platform or Services, neither
						should you give nor enable Bouk receive personal information from
						you a California resident or EEA citizen residing in that country.
					</Typography>
				</Box>
				<Box>
					<BoldText>23. General Agreement.</BoldText> <br />
					<Typography>
						This Agreement represents both parties' ultimate, full,
						comprehensive, and final agreement on this particular subject
						herein, and it supersedes any previous talks among both parties on
						the specific subject. The conditions of any different agreement(s)
						amongst the customer and Bouk pertaining to the user's job as a
						worker or independent contractor, which would include, any sort of
						Independent Contractor Agreement governing the user's actions as a
						Contractor, shall not be superseded, amended, or modified by this
						Agreement.
					</Typography>
				</Box>
			</Container>
		</AppContainer>
	);
};

const BoldText = styled(Typography)({
	fontWeight: 'bold',
});

export default TermsAndConditions;
