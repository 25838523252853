import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
	TextField,
	Box,
	Typography,
	Button,
	InputAdornment,
	IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Alert from '../../../components/alert';
import { AUTH_VIEW } from '../enum';
import { useDispatch } from 'react-redux';
import { register } from '../../../redux/authSlice';
import { resetLoading } from '../../../redux/authSlice';
import PhoneTextField from '../../../components/form-input/phone-text-field';
import { useToastAlert } from '../../../hooks';

const SignUp = ({ setCurrentAuthView, setPayload }) => {
	const dispatch = useDispatch();
	const alertRef = useRef(null);
	const alert = useToastAlert();

	const [isTogglePassword, setTogglePassword] = useState(false);
	const [isToggleConfirmPassword, setToggleConfirmPassword] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const initialValues = {
		name: '',
		phoneNumber: '',
		email: '',
		password: '',
		confirmPassword: '',
	};

	const validationSchema = yup.object().shape({
		name: yup.string().required('Enter a name'),
		phoneNumber: yup.string().required('Enter a phone number'),
		email: yup.string().email('Invalid email').required('Enter a email'),
		password: yup.string().required('Enter a password'),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref('password')], 'Password do not match')
			.required('Confirm password is required'),
	});

	const onSubmit = async (values) => {
		alertRef.current.close();
		setLoading(true);

		const device_id = localStorage.getItem('fbToken')
			? JSON.parse(localStorage.getItem('fbToken'))
			: 'device_token';

		const phone_number =
			values.phoneNumber.charAt(0) === '+'
				? values.phoneNumber
				: `+${values.phoneNumber}`;

		const payload = {
			name: values.name,
			email: values.email,
			phone_number,
			password: values.password,
			device_id,
		};

		try {
			const res = await dispatch(register(payload)).unwrap();

			setLoading(false);

			if (res.status === 'successful') {
				alert({
					message: res?.message || 'Registration is successful!',
					type: 'success',
				});
				const data = res.data;
				typeof setPayload === 'function' && setPayload(data);
				setCurrentAuthView(AUTH_VIEW.ENTER_CODE.value);
				resetLoading();
				return;
			}
		} catch (error) {
			setLoading(false);

			alertRef.current.show({ message: `${error.message}`, type: 'error' });
		}
	};

	const { values, handleChange, handleSubmit, errors, touched } = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	});

	const { name, email, phoneNumber, password, confirmPassword } = values;

	return (
		<Box
			sx={{
				display: 'grid',
				gap: '15px',
			}}
		>
			<Box sx={{ marginBottom: '20px' }}>
				<Typography sx={{ marginBottom: '8px' }} variant='h4'>
					Sign Up
				</Typography>
				<Typography variant='body1' className='desc'>
					Please fill in your details to continue
				</Typography>
			</Box>
			<Alert
				ref={alertRef}
				sx={{
					marginBottom: '20px',
				}}
			/>
			<TextField
				variant={'outlined'}
				label='Name'
				placeholder='Full name'
				value={name}
				onChange={handleChange('name')}
				error={Boolean(touched.name && errors.name)}
				helperText={touched.name && errors.name}
			/>
			<TextField
				variant={'outlined'}
				label='Email'
				placeholder='email address'
				value={email}
				onChange={handleChange('email')}
				error={Boolean(touched.email && errors.email)}
				helperText={touched.email && errors.email}
			/>

			<PhoneTextField
				fullWidth={true}
				onlyCountries={['us']}
				value={phoneNumber}
				onChange={handleChange('phoneNumber')}
				inputProps={{
					name: '',
					required: true,
					variant: 'standard',
				}}
				error={Boolean(touched.phoneNumber && errors.phoneNumber)}
				helperText={touched.phoneNumber && errors.phoneNumber}
			/>

			<TextField
				label={'Password'}
				type={isTogglePassword ? 'text' : 'password'}
				variant={'outlined'}
				placeholder='Password'
				value={password}
				onChange={handleChange('password')}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton
								onClick={(e) => {
									setTogglePassword(!isTogglePassword);
								}}
							>
								{isTogglePassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					),
				}}
				error={Boolean(touched.password && errors.password)}
				helperText={touched.password && errors.password}
			/>

			<TextField
				label={'Confirm Password'}
				type={isToggleConfirmPassword ? 'text' : 'password'}
				variant={'outlined'}
				placeholder='Confirm Password'
				value={confirmPassword}
				onChange={handleChange('confirmPassword')}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton
								onClick={(e) => {
									setToggleConfirmPassword(!isToggleConfirmPassword);
								}}
							>
								{isToggleConfirmPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					),
				}}
				error={Boolean(touched.confirmPassword && errors.confirmPassword)}
				helperText={touched.confirmPassword && errors.confirmPassword}
			/>

			<Button
				disabled={isLoading}
				size={'large'}
				sx={{
					textTransform: 'capitalize',
					color: 'white',
					backgroundColor: '#6438b0 !important',
				}}
				onClick={(e) => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				{isLoading ? 'Processing...' : 'Continue'}
			</Button>

			<Box
				sx={{
					textAlign: 'center',
					span: {
						color: ' #6438b0',
						fontWeight: 'bold',
						cursor: 'pointer',
					},
				}}
			>
				Already have an account?{'  '}
				<span
					onClick={(e) => {
						e.preventDefault();
						setCurrentAuthView(AUTH_VIEW.SIGNIN.value);
					}}
				>
					Login
				</span>
			</Box>
		</Box>
	);
};

export default SignUp;
