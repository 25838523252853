import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { Box, FormHelperText } from '@mui/material';
import { red } from '@mui/material/colors';

const PhoneTextField = ({ value, onChange, country = 'us', ...rest }) => {
	const helperText = rest.helperText;
	const error = rest.error;
	return (
		<Box
			sx={{
				'.MuiOutlinedInput-notchedOutline': {
					borderColor: 'red',
				},
				'.form-control': {
					width: '100% !important',
					paddingTop: '16.5px',
					paddingBottom: '16.5px',
					borderColor: error ? `${red['700']} !important` : undefined,
				},
			}}
		>
			<PhoneInput
				containerStyle={{
					padding: '0px',
					width: '100%',
				}}
				{...rest}
				country={country}
				value={value}
				onChange={onChange}
			/>
			{helperText && (
				<FormHelperText
					sx={{
						margin: '3px 14px 0px',
					}}
					error={error}
				>
					{helperText}
				</FormHelperText>
			)}
		</Box>
	);
};

export default PhoneTextField;
