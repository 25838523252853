import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box, Typography, Card } from '@mui/material';
import { grey } from '@mui/material/colors';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { getDeliveries } from '../../../../../redux/orderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Rings } from 'react-loader-spinner';
import DeliveryItem from './delivery-item';
import DeliveryDetails from './delivery-details';

const Deliveries = () => {
	const [deliveryData, setDeliveryData] = React.useState(null);
	const [selectedDelivery, setSelectedDelivery] = useState(null);
	const [isLoading, setLoading] = useState(false);

	const dispatch = useDispatch();

	const getData = async () => {
		try {
			setLoading(true);
			const res = await dispatch(getDeliveries()).unwrap();
			if (res.status === 'successful') {
				const data = res.data;
				setLoading(false);

				setDeliveryData(data);
			}
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	React.useEffect(
		() => {
			getData();
		},
		// eslint-disable-next-line
		[]
	);

	return (
		<Box
			sx={{
				padding: '20px',
				display: 'grid',
				gap: '20px',
				gridTemplateColumns: {
					xs: '1fr',
					md: 'repeat(2, 1fr)',
				},
			}}
		>
			<Box>
				{isLoading ? (
					<Box>
						<Typography variant='body1'>Loading deliveries...</Typography>
					</Box>
				) : (
					deliveryData && (
						<>
							{deliveryData.length > 0 ? (
								<Box
									sx={{
										display: 'grid',

										border: `1px solid ${grey[200]}`,
										borderRadius: '6px',
									}}
								>
									{deliveryData.map((delivery, index) => {
										const deliveryDataLength = deliveryData.length;
										const hasDivider = deliveryDataLength !== index + 1;
										return (
											<DeliveryItem
												key={delivery.id}
												isActive={selectedDelivery?.id === delivery.id}
												hasDivider={hasDivider}
												delivery={delivery}
												onClick={() => setSelectedDelivery(delivery)}
											/>
										);
									})}
								</Box>
							) : (
								<Box>
									<Typography>No available delivery</Typography>
								</Box>
							)}
						</>
					)
				)}
			</Box>
			{selectedDelivery && <DeliveryDetails delivery={selectedDelivery} />}
		</Box>
	);
};

export default Deliveries;
