import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generalService } from '../services/GeneralService';
import { handleResponseError } from '../helpers/handle-response-errors';

export const sendToken = createAsyncThunk(
	'general/sendToken',
	async (payload) => {
		try {
			const response = await generalService.sendToken(payload);
			return response.data;
		} catch (error) {
			const message = handleResponseError({ error });
			throw new Error(message);
		}
	}
);

const generalSlice = createSlice({
	name: 'general',
	initialState: {
		userLocation: {},
		bookmarkStatus: false,
		webToken: '',
		driverAccepted: false,
		pickedUp: false,
		droppedOff: false,
		hasAccount: true,
		isActivitySearchActive: false,
		activeTab: 0,
		appSetting: {},
		country: 'GH',
		countryCode: '+233',
		currency: 'GHS',
		countries: [],
		banks: [],
		mobileProviders: [],
		hasMobileMoney: ['GHS'],
		reanimate: false,
		toast: {
			type: 'success',
			message: 'Hello bro',
			show: false,
		},
		show: false,
		toggle: false,
	},
	reducers: {
		setLocation: (state, payload) => {
			state.userLocation.lat = payload.lat;
			state.userLocation.lng = payload.lng;
		},
		setToken: (state, { payload }) => {
			state.webToken = payload;
		},
		setDriverAccepted: (state, { payload }) => {
			state.driverAccepted = payload;
		},
		extraReducers: (builder) => {
			builder.addCase(sendToken.fulfilled, (state, action) => {
				state.webToken = action.data.payload;
			});
		},
	},
});
export const { setToken, setDriverAccepted } = generalSlice.actions;
export default generalSlice.reducer;
