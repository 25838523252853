import React, { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const TIPS = ['4', '6', '10'];

const TipItem = ({ text, isSelected, onSelect }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				cursor: 'pointer',
				padding: '10px 6px',
				flex: 1,
				backgroundColor: isSelected ? '#3E236E' : undefined,
			}}
			onClick={onSelect}
		>
			<Typography
				sx={{
					color: isSelected ? 'white !important' : grey['700'],
				}}
			>
				{text}
			</Typography>
		</Box>
	);
};

const SelectTip = ({ onSelectTip, selectedTip, handleNoTip }) => {
	const [isDisplayTextField, setDisplayTextField] = useState(false);
	const handleSelect = (value) => {
		if (value === 'others') {
			setDisplayTextField(true);
			return;
		}

		if (value === 'no-tip') {
			return;
		}

		typeof onSelectTip === 'function' && onSelectTip(value);
	};
	return (
		<Box>
			{isDisplayTextField ? (
				<Box>
					<Typography sx={{ marginBottom: '6px' }}>Enter Tip</Typography>
					<TextField
						fullWidth
						value={`${selectedTip}`}
						onChange={(e) => {
							onSelectTip(e.target.value);
						}}
						type='number'
						variant='outlined'
						placeholder='Enter tip'
					/>
				</Box>
			) : (
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(5, 1fr)',
						border: `1px solid ${grey['400']}`,
						div: {
							borderRight: `1px solid ${grey['400']}`,
						},
						'div:last-child': {
							borderRight: `none !important`,
						},
					}}
				>
					<TipItem onSelect={() => handleSelect('others')} text={'Others'} />
					{TIPS.map((value) => (
						<TipItem
							text={value}
							key={value}
							isSelected={selectedTip === value}
							onSelect={() => handleSelect(value)}
						/>
					))}
					<TipItem onSelect={handleNoTip} text={'No Tip'} />
				</Box>
			)}
		</Box>
	);
};

export default SelectTip;
