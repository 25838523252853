import axios from "axios";
import { handleResponseError } from "./helpers/handle-response-errors";

const nodeEnv = process.env.REACT_APP_ENV;

const baseUrls = {
  development: process.env.REACT_APP_DEV_API_URL,
  staging: process.env.REACT_APP_STAGE_API_URL,
  production: process.env.REACT_APP_PROD_API_URL,
};

export const BASE_URL = baseUrls[nodeEnv];
// const TOKEN = http://localhost:8001/"
//   JSON.parse(JSON.parse(localStorage.getItem("auth:details")).user).currentUser
//     .accessToken || "";
console.log("PROCESS_ENV::", process.env);
console.log("NODE_ENV::", nodeEnv);
console.log("BASE_URL_MAP::", baseUrls);
console.log("SELECTED_URL::", BASE_URL);

axios.defaults.baseURL = BASE_URL;
console.log("DEFAULT_BASE_URL::", axios.defaults.baseURL);
export const publicRequest = axios.create();

export const userRequest = axios.create();

userRequest.interceptors.request.use(
  (config) => {
    const authDetails = localStorage.getItem("auth:details");
    const currentUser =
      authDetails && Object.keys(authDetails).length > 0
        ? JSON.parse(authDetails)
        : null;

    const TOKEN = currentUser.access_token;

    if (TOKEN) {
      config.headers["Authorization"] = "Bearer " + TOKEN;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

userRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    const dataError =
      error && error.response && error.response?.data
        ? error.response?.data
        : error;

    let authDetails = localStorage.getItem("authDetails")
      ? JSON.parse(localStorage.getItem("authDetails"))
      : null;

    if (error.response.status === 401) {
      originalRequest._retry = true;

      const TOKEN = authDetails.refresh_token;

      try {
        const response = await publicRequest.post("/auth/refresh-token", {
          token: TOKEN,
        });

        if (response) {
          const access_token = response.data.data.access_token;
          const refresh_token = response.data.data.refresh_token;

          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${access_token}`;

          authDetails = {
            ...authDetails,
            access_token,
            refresh_token,
          };
        }

        localStorage.setItem("auth:details", JSON.stringify(authDetails));

        return axios(originalRequest);
      } catch (error) {
        const message = handleResponseError({ error });
        throw new Error(message);
      }
    }
    return Promise.reject(dataError);
  }
);
