import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { transactionService } from '../services/TransactionService';
import { handleResponseError } from '../helpers/handle-response-errors';

export const initializeTransaction = createAsyncThunk(
	'transaction/initializeTransaction',
	async (payload) => {
		try {
			const response = await transactionService.initializeTransaction(payload);
			return response.data;
		} catch (error) {
			const message = handleResponseError({ error });
			throw new Error(message);
		}
	}
);

export const initializeTipTransaction = createAsyncThunk(
	'transaction/initializeTipTransaction',
	async ({ payload, id }) => {
		try {
			const response = await transactionService.initializeTipTransaction({
				payload,
				id,
			});
			return response.data;
		} catch (error) {
			const message = handleResponseError({ error });
			throw new Error(message);
		}
	}
);

const transactionSlice = createSlice({
	name: 'transaction',
	initialState: {
		transactionPayload: {},
		transactions: [],
		showPaymentModal: false,
		loading: 'idle',
		cancelFee: 0,
	},
	reducers: {
		setTransactionPayload: (state, { payload }) => {
			state.transactionPayload = payload;
		},
		setPaymentModal: (state, { payload }) => {
			state.showPaymentModal = payload;
		},
		resetTransactionState: (state, action) => {
			state.transactionPayload = {};
			state.transactions = [];
			state.loading = 'idle';
			state.cancelFee = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(initializeTransaction.pending, (state, action) => {
			state.loading = 'loading';
		});
		builder.addCase(initializeTransaction.fulfilled, (state, action) => {
			state.loading = 'succeeded';
		});
		builder.addCase(initializeTransaction.rejected, (state, action) => {
			state.loading = 'failed';
			state.error = action.error.message;
		});
	},
});

export const { setTransactionPayload, setPaymentModal, resetTransactionState } =
	transactionSlice.actions;

export default transactionSlice.reducer;
