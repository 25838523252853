import { userRequest } from '../requestMethods';

const getTransaction = async () => {
	return userRequest.get('customer/store/all');
};

const initializeTransaction = async (payload) => {
	return userRequest.post('customer/pay', payload);
};

const initializeTipTransaction = async ({ id, payload }) => {
	return userRequest.post(`customer/delivery/${id}/give-tip`, payload);
};

const transactionService = {
	getTransaction,
	initializeTransaction,
	initializeTipTransaction,
};

export { transactionService };
