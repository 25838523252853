import React, {
	useImperativeHandle,
	forwardRef,
	useState,
	useRef,
} from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { useDispatch } from 'react-redux';
import ModalLayout from '../modal/modal-layout';
import DefaultButton from '../btn/default-button';
import SelectTip from './select-tip';
import Alert from '../alert';
import { tipDriver } from '../../redux/orderSlice';

const TipDriver = (props, ref) => {
	const dispatch = useDispatch();
	const [isDisplay, setDisplay] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [hasTipDriver, setHasTipDriver] = useState(false);

	const driver = props?.driver;
	const backToHome = props?.backToHome;
	const deliveryId = props?.deliveryId;
	const handleTipDriver = props?.tipDriver;

	const alertRef = useRef(null);

	const driverName = driver && driver['Name'];

	const [tip, setTip] = useState('');

	useImperativeHandle(
		ref,
		() => ({
			show: () => {
				setDisplay(true);
			},
			completeTip: () => {
				setDisplay(true);
				setHasTipDriver(true);
			},
			close: () => setDisplay(false),
		}),
		[]
	);

	const closeModal = () => setDisplay(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!tip) {
			alertRef.current.show({
				message: 'Please select a tip',
				type: 'error',
			});

			return;
		}

		try {
			setLoading(true);
			const response = await dispatch(
				tipDriver({
					id: deliveryId,
					data: {
						amount: parseFloat(tip),
					},
				})
			).unwrap();
			setLoading(false);

			if (response.status === 'successful') {
				const token = response.data.token;
				typeof handleTipDriver === 'function' && handleTipDriver(token);

				closeModal();
			}
		} catch (error) {
			setLoading(false);

			alertRef.current.show({
				message: `${error.message}`,
				type: 'error',
			});
		}
	};

	const handleBackToHome = () => {
		closeModal();
		typeof backToHome === 'function' && backToHome();
	};

	if (isDisplay) {
		return (
			<ModalLayout
				isOpen={isDisplay}
				sx={{
					maxWidth: '540px',
				}}
				hasCloseButton={true}
				closeModal={closeModal}
			>
				<Alert
					ref={alertRef}
					sx={{
						marginBottom: '20px',
					}}
				/>
				{hasTipDriver ? (
					<Box
						sx={{
							display: 'grid',
							gap: '20px',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								img: {
									width: '100px',
									height: '100px',
								},
							}}
						>
							<img src='images/confetti.png' alt='confetti' />
						</Box>
						<Box>
							<Typography
								sx={{ textAlign: 'center', marginBottom: '8px' }}
								variant='h5'
							>
								Tip Driver
							</Typography>
							<Typography sx={{ textAlign: 'center' }}>
								you have successfully tip {driverName}!
							</Typography>
						</Box>
						<DefaultButton
							isLoading={isLoading}
							size='large'
							sx={{
								color: 'white',
							}}
							onClick={handleBackToHome}
							buttonText={'Close'}
						/>
					</Box>
				) : (
					<Box
						sx={{
							display: 'grid',
							gap: '15px',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Avatar
								sx={{
									height: '80px',
									width: '80px',
									marginBottom: '10px',
								}}
								src={driver['PhotoURL'] || driver['PassportPhotographURL']}
								alt={'Driver Photo'}
							/>
						</Box>
						<Typography sx={{ textAlign: 'center' }} variant='h5'>
							Tip {driverName}
						</Typography>

						<SelectTip
							handleNoTip={handleBackToHome}
							selectedTip={tip}
							onSelectTip={(value) => setTip(value)}
						/>
						<DefaultButton
							isLoading={isLoading}
							size='large'
							sx={{
								color: 'white',
							}}
							onClick={handleSubmit}
							buttonText={'Submit'}
						/>
					</Box>
				)}
			</ModalLayout>
		);
	}

	return null;
};

export default forwardRef(TipDriver);
