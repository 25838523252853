import React from 'react';
import styled from '@emotion/styled';
import { Box, Typography, Container, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Cta from '../home/components/cta';
import Footer from '../../components/Footer';
import Partners from '../home/components/partners';
import Testimonials from '../home/components/testimonials';
import DrawerAppBar from '../../components/Navbar';
import { ReactComponent as Playstore } from '../../assets/play-store.svg';
import { ReactComponent as Appstore } from '../../assets/apple-store.svg';
import Cards from './components/cards';
import Details from './components/details';
import { grey } from '@mui/material/colors';

const HeadingBackground = styled.div`
	height: 600px;
	position: relative;
`;

const Overlay = styled.div`
	width: 100%;
	height: 100%;
	background: rgba(62, 35, 110, 0.7);
`;

const Mission = styled.div`
	background: #f7f8fb;
	text-align: center;
	padding: 70px 20px;

	h2 {
		color: #181725;
		font-size: 35px;
		font-weight: 700;
		font-family: 'Oxygen', sans-serif;
	}

	p {
		max-width: 700px;
		width: 100%;
		font-style: normal;
		font-weight: 400;
		margin: 0 auto;
		color: #5a5f63;
		font-size: 16px;
		font-family: 'Oxygen', sans-serif;
	}

	@media screen and (max-width: 576px) {
		padding: 40px 20px;
	}
`;

export default function Driver() {
	const navigate = useNavigate();

	return (
		<div style={{ backgroundColor: '#fff' }}>
			<DrawerAppBar />
			<HeadingBackground
				style={{
					backgroundImage: `url(
            images/boukbackground.png
        )`,
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: '100%',
				}}
			>
				<Overlay>
					<Container>
						<Box
							sx={{
								color: 'white',
								display: 'flex',
								flexDirection: 'column',
								gap: '20px',
								justifyContent: 'center',
								alignItems: 'flex-start',
								padding: '20px 0px',
								height: '600px',
								'h1, h3, p': {
									color: 'white',
								},
							}}
						>
							<Typography variant={'h3'} component={'h1'}>
								Get paid being your own boss
							</Typography>
							<Typography variant={'h5'} component={'h3'}>
								Pick-up, drop-off, and earn with Bouk
							</Typography>
							<Typography variant={'body1'}>
								What you really need is a mode of transportation and a
								smartphone to start <br />
								making money. it's that simple and Bouk is here to give you that
								platform.
							</Typography>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: '10px',
									button: {
										// maxWidth: ['160px', '120px'],
										// padding: '15px 20px',
									},
									'& .appstore, & .playstore': {
										maxWidth: ['120px', '180px'],
										height: ['65px'],
									},
									a: {
										maxWidth: ['120px', '180px'],
										width: '100%',
									},
									img: {
										width: '100%',
									},
								}}
							>
								<a
									target='_blank'
									rel='noreferrer'
									href='https://apps.apple.com/us/app/bouk/id1625693857'
								>
									<img src='/images/appstore.png' alt='appstore' />
								</a>
								<a
									target='_blank'
									rel='noreferrer'
									href='https://play.google.com/store/apps/details?id=ng.bouk.bouk_driver'
								>
									<img src='/images/playstore.png' alt='playstore' />
								</a>

								<Button
									onClick={() => navigate('/driver/register')}
									sx={{
										backgroundColor: '#fddc5c !important',
										color: grey['700'],
										padding: '13px 20px',
										minWidth: ['160px', '180px'],
										textTransform: 'unset',
										borderRadius: '0px',
									}}
								>
									Register
								</Button>
							</Box>
						</Box>
					</Container>
				</Overlay>
			</HeadingBackground>

			<Mission>
				<h2>Bouk</h2>
				<p>
					Building a future where one platform solves all your delivery and
					shopping problems because there is a delivery person for every item no
					matter the size or weight.
				</p>
			</Mission>

			<Cards />
			<Details />
			<Testimonials />
			<Cta />
			<Partners />
			<Footer />
		</div>
	);
}

{
	/* <Stores>
							<Button
								onClick={(event) =>
									(window.location.href =
										'https://apps.apple.com/us/app/bouk-driver/id1625693857')
								}
								startIcon={<Appstore className='appstore' />}
							>
								{' '}
							</Button>
							<Button
								onClick={(event) =>
									(window.location.href =
										'https://play.google.com/store/apps/details?id=ng.bouk.bouk_driver')
								}
								startIcon={<Playstore className='playstore' />}
							>
								{' '}
							</Button>
						</Stores> */
}
