export const handleResponseError = ({ error, callback, customMessage }) => {
	console.log(error);
	if (error && Object.keys(error).length > 0) {
		if (error.response) {
			if (callback && typeof callback === 'function') callback(); // Callback funcion
			if (error.response.data) {
				const data = error.response.data;

				if (data.message) return data.message;
				if (data.data) return data.data;
			}
		}

		if (error?.error) {
			return error.error.message;
		}

		if (error?.message) return error.message;

		return customMessage || 'Something went wrong';
	}
};
