import React, { useRef, useState, useCallback } from 'react';
import { Box, Button, Typography, FormHelperText } from '@mui/material';
import ReactCrop, { makeAspectCrop, centerCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Upload } from '@mui/icons-material';
import { green, grey, red } from '@mui/material/colors';
import ModalLayout from '../../../components/modal/modal-layout';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 90,
			},
			aspect,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	);
}

const ImageUpload = ({
	text = 'Upload image',
	aspect = 1,
	setImageData,
	name,
	icon,
	error,
	helperText,
}) => {
	const fileInputRef = useRef(null);
	const [file, setFile] = useState(null);
	const [src, setSrc] = useState(null);
	const [isDisplayModal, setDisplayModal] = useState(false);
	const [croppedImageUrl, setCroppedImageUrl] = useState(null);

	const [crop, setCrop] = useState();
	const imageRef = useRef();

	const closeModal = () => {
		setDisplayModal(false);
		fileInputRef.current.value = '';
		setCrop(undefined);
		setSrc(null);
		setFile(null);
		setCroppedImageUrl(null);
	};

	const handleSelectImage = () => {
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
			fileInputRef.current.click();
		}
	};

	const handleImageUpload = (e) => {
		setCrop(undefined);
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			setFile(file);
			const reader = new FileReader();
			reader.addEventListener('load', () => setSrc(reader.result));
			reader.readAsDataURL(file);
			setDisplayModal(true);
		}

		// setImages(files);
	};

	const onImageLoad = (e) => {
		imageRef.current = e.currentTarget;

		if (aspect) {
			const { width, height } = e.currentTarget;
			setCrop(centerAspectCrop(width, height, aspect));
		}
	};

	const getCroppedImg = (image, crop, fileName) => {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(croppedImageUrl);
				// const fileUrl = window.URL.createObjectURL(blob);
				// resolve(fileUrl);
				const fileData = new File([blob], file?.name || 'cropped-image.jpg', {
					type: 'image/jpeg',
				});
				resolve(fileData);
			}, 'image/jpeg');
		});
	};

	const onCropComplete = useCallback(
		async (crop) => {
			setCrop(crop);
			try {
				if (imageRef.current && crop.width && crop.height) {
					console.log('CROP::', crop, file);
					const croppedImageUrl = await getCroppedImg(
						imageRef.current,
						crop,
						file?.name
					);
					setCroppedImageUrl(croppedImageUrl);
				}
			} catch (error) {
				console.log(error);
			}
		},
		// eslint-disable-next-line
		[file]
	);

	const handleApply = async () => {
		if (typeof setImageData === 'function' && file && crop) {
			setImageData({
				file,
				croppedImageUrl,
			});
		}

		// Close Modal
		closeModal();
	};

	return (
		<>
			<ModalLayout
				isOpen={isDisplayModal}
				sx={{
					maxWidth: '540px',
				}}
				hasCloseButton={true}
				closeModal={closeModal}
			>
				<Typography sx={{ marginBottom: '15px' }} variant={'h5'}>
					Resize Image
				</Typography>
				{src && (
					<>
						<ReactCrop
							aspect={aspect}
							src={src}
							crop={crop}
							onChange={(_, percentCrop) => setCrop(percentCrop)}
							onComplete={onCropComplete}
							// minWidth={400}
							minHeight={100}
						>
							<img src={src} alt='crop' onLoad={onImageLoad} />
						</ReactCrop>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Button
								onClick={handleApply}
								sx={{
									backgroundColor: '#3e236e !important',
									color: 'white',
									textTransform: 'unset',
									minWidth: '120px',
								}}
							>
								Apply
							</Button>
						</Box>
					</>
				)}
			</ModalLayout>
			<Box>
				<Box
					onClick={handleSelectImage}
					sx={{
						border: `1px solid ${error ? red['600'] : grey[300]}`,
						padding: '16.5px 14px',
						borderRadius: '6px',
						display: 'flex',
						// flexDirection: 'column',
						gap: '6px',
						alignItems: 'center',
						justifyContent: 'space-between',
						cursor: 'pointer',
						userSelect: 'none',
					}}
				>
					<input
						ref={fileInputRef}
						style={{
							display: 'none',
						}}
						type='file'
						accept='image/*'
						onChange={handleImageUpload}
					/>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '6px',
						}}
					>
						{name ? <Upload /> : icon}
						<Typography
							sx={{
								color: name ? green['600'] : grey['700'],
							}}
							variant='body2'
						>
							{name || text}
						</Typography>
					</Box>
					<Typography
						onClick={(e) => {
							e.stopPropagation();
							if (name) {
								setImageData(null);
								return;
							}
							handleSelectImage();
						}}
						sx={{
							color: name ? red['600'] : grey['700'],
						}}
					>
						{name ? 'remove' : 'upload'}
					</Typography>
				</Box>
				{helperText && (
					<FormHelperText sx={{ color: red['600'] }}>
						{helperText}
					</FormHelperText>
				)}
			</Box>
		</>
	);
};

export default ImageUpload;
